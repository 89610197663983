const SessionInstrumentalistServiceEnum = {
  Rehearsals: 'Rehearsals',
  Performances: 'Performances',
  Recordings: 'Recordings',
}

type SessionInstrumentalistServiceEnumType =
  typeof SessionInstrumentalistServiceEnum[keyof typeof SessionInstrumentalistServiceEnum]

function getSessionInstrumentalistService(
  value: SessionInstrumentalistServiceEnumType
): SessionInstrumentalistServiceEnumType[] {
  const output = []
  output.push(SessionInstrumentalistServiceEnum.Rehearsals)
  output.push(SessionInstrumentalistServiceEnum.Performances)
  output.push(SessionInstrumentalistServiceEnum.Recordings)
  return output
}

function printSessionInstrumentalistService(value: SessionInstrumentalistServiceEnumType): string {
  switch (value) {
    case SessionInstrumentalistServiceEnum.Rehearsals:
      return 'Rehearsals'
    case SessionInstrumentalistServiceEnum.Performances:
      return 'Performances'
    case SessionInstrumentalistServiceEnum.Recordings:
      return 'Recordings'
    default:
      return ''
  }
}

export {
  getSessionInstrumentalistService,
  printSessionInstrumentalistService,
  SessionInstrumentalistServiceEnum,
}
