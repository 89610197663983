import { convertEnumToOptionsWithCleanLabel } from "../utils";

const OriginalContentCoversEnum = {
  Original: 'Original',
  Covers: 'Covers',
  Both: 'Both',
};

type OriginalContentCoversEnumType = typeof OriginalContentCoversEnum[keyof typeof OriginalContentCoversEnum];

function getOriginalCovers(): OriginalContentCoversEnumType[]{
  const output = [];
  output.push(OriginalContentCoversEnum.Original);
  output.push(OriginalContentCoversEnum.Covers);
  output.push(OriginalContentCoversEnum.Both);
  return output;
}

function getOriginalCoversEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(OriginalContentCoversEnum, printOriginalCovers)
}

function printOriginalCovers(value: OriginalContentCoversEnumType): string {
  switch (value) {
    case OriginalContentCoversEnum.Original:
      return 'Originals';
    case OriginalContentCoversEnum.Covers:
      return 'Covers';
    case OriginalContentCoversEnum.Both:
      return 'Both';
    default:
      return '';
  }
}

export { getOriginalCovers, printOriginalCovers, OriginalContentCoversEnum, getOriginalCoversEnumOptions };
