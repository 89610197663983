import { convertEnumToOptionsWithCleanLabel } from "../utils"

enum ArtistMusicLiteracyEnum {
  Charts = 'Charts',
  Tabs = 'Tabs',
  MusicNotation = 'MusicNotation',
  None = 'None',
}

function getArtistMusicLiteracy(): string[] {
  return Object.values(ArtistMusicLiteracyEnum)
}

function getArtistMusicLiteracyEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(ArtistMusicLiteracyEnum, printArtistMusicLiteracy)
}

function printArtistMusicLiteracy(value: ArtistMusicLiteracyEnum): string | undefined {
  switch (value) {
    case ArtistMusicLiteracyEnum.Charts:
      return 'Charts'
    case ArtistMusicLiteracyEnum.Tabs:
      return 'Tabs'
    case ArtistMusicLiteracyEnum.MusicNotation:
      return 'Music Notation'
    case ArtistMusicLiteracyEnum.None:
      return 'I do not read music'
    default:
      return undefined
  }
}

export {getArtistMusicLiteracy, printArtistMusicLiteracy, ArtistMusicLiteracyEnum,getArtistMusicLiteracyEnumOptions}
