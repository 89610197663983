
enum SchoolAcademyLessonsAdministartionTimesEnum {
DailyAdministration = 'DailyAdministration',
MonthlyAdministration = 'MonthlyAdministration',
BySemesterTerm = 'BySemesterTerm',
Yearly = 'Yearly',
}

type SchoolAcademyLessonsAdministartionTimesType = typeof SchoolAcademyLessonsAdministartionTimesEnum[keyof typeof SchoolAcademyLessonsAdministartionTimesEnum]

function getSchoolAcademyLessonsAdministartionTimesTypes(): SchoolAcademyLessonsAdministartionTimesType[] {
        return Object.values(SchoolAcademyLessonsAdministartionTimesEnum);
      } 



function printSchoolAcademyLessonsAdministartionTimes(value: SchoolAcademyLessonsAdministartionTimesEnum): string | undefined { 
switch (value) { 
       case SchoolAcademyLessonsAdministartionTimesEnum.DailyAdministration:
        return 'Daily Administration.';
       case SchoolAcademyLessonsAdministartionTimesEnum.MonthlyAdministration:
        return 'Monthly Administration.';
       case SchoolAcademyLessonsAdministartionTimesEnum.BySemesterTerm:
        return 'By Semester / Term';
       case SchoolAcademyLessonsAdministartionTimesEnum.Yearly:
        return 'Yearly';
      default:
    return "";
        }
     }


export { getSchoolAcademyLessonsAdministartionTimesTypes, printSchoolAcademyLessonsAdministartionTimes, SchoolAcademyLessonsAdministartionTimesEnum }; 
