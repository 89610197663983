import {isEmpty} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'

interface InputProps {
  errors?: any
  label: string
  name: string
  className: string
  control: Control<any, any>
  required?: boolean
}

const CheckBox = ({label, name, errors, className, control, required = false}: InputProps) => {
  return (
    <div className={`form-floating ${className}`}>
      <Controller
        render={({field}) => (
          <div className={`form-check form-check-custom form-check-solid`}>
            <input
              className='form-check-input mr-3'
              type='checkbox'
              {...field}
              defaultChecked={field.value}
            />
            <label className='ml-3' htmlFor={`chck-${name}`}>
              {label}
            </label>
          </div>
        )}
        control={control}
        rules={{required: required}}
        name={name}
      />
      {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
    </div>
  )
}

export default CheckBox
