import {useQueryClient, useMutation} from '../../../infrastructure/deps/react-query'
import { AuthenticationRepositoryImpl } from '../../../infrastructure/remote/repositories/authentication/AuthenticationRepositoryImpl'
import {LoginRequest} from '../../models/requests/authentication'
import { ChangePasswordRequest } from '../../models/requests/authentication/ChangePasswordRequest'


export const useChangePassword = (repository: AuthenticationRepositoryImpl) => {
  const queryClient = useQueryClient()

  const resetPassword = useMutation({
    mutationFn: (request:ChangePasswordRequest) => repository.useChangePassword(request),
    onSuccess: (res) => {
      console.log(res)
      queryClient.invalidateQueries({queryKey: ['changePassword']})
      // notify.success('Created Correctly!')
    },
    onError: (error) => {
      console.log(error)
      // logger.error("Something goes wrong..");
    },
  })
  return resetPassword
}
