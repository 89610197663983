import {BaseResponse} from '../../../../domain/models'
import { BaseFilter } from '../../../../domain/models/filters/BaseFilter'
import {OnboardingRequest} from '../../../../domain/models/requests/common/OnboardingRequest'
import { SwitchUserRequest } from '../../../../domain/models/requests/user/SwitchUserRequest'
import {UserRequest} from '../../../../domain/models/requests/user/UserRequest'
import {ActionResponse} from '../../../../domain/models/responses/ActionResponse'
import {ResponseData} from '../../../../domain/models/responses/ResponseData'
import {UserApi} from '../../api/UserApi'
import {UserRepository} from './UserRepository'

export class UserRepositoryImpl implements UserRepository {
  api: UserApi

  constructor(api: UserApi) {
    this.api = api
  }

  async useUserOnboarding(request: OnboardingRequest): Promise<BaseResponse> {
    console.log('UserRepositoryImpl -useUserOnboarding: ', request)
    return this.api.useUserOnboarding(request)
  }
  async useCreate(request: Omit<UserRequest, 'id'>): Promise<BaseResponse> {
    return this.api.useCreate(request)
  }  

  async useUserSwitchAccount(request: Omit<SwitchUserRequest, 'id'>): Promise<BaseResponse> {
    return this.api.useUserSwitchAccount(request)
  }
  async useUpdate(request: UserRequest): Promise<ActionResponse> {
    return this.api.useUpdate(request)
  }

  async useUserAccountReset(request: UserRequest): Promise<ActionResponse> {
    return this.api.useUserAccountReset(request)
  }
  async useDelete(request: UserRequest): Promise<ActionResponse> {
    return this.api.useDelete(request)
  }
  async useGetFilter(filter: BaseFilter): Promise<ResponseData> {
    return this.api.useGetFilter(filter)
  }

  async useUserLogout(): Promise<ResponseData> {
    return this.api.useUserLogout()
  }
}
