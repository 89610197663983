
  import {useQueryClient, useMutation} from '../../../infrastructure/deps/react-query'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import { OnboardingRequest } from '../../models/requests/common/OnboardingRequest'
import {UserRequest} from '../../models/requests/user/UserRequest'

export const useUserOnboarding = (repository: UserRepositoryImpl) => {
  const queryClient = useQueryClient()
  const newOnboarding = useMutation({
    mutationFn: (request: OnboardingRequest) => repository.useUserOnboarding(request),
    onSuccess: (res) => {
      console.log(res)
      queryClient.invalidateQueries({queryKey: ['newOnboarding']})
      // notify.success('Created Correctly!')
    },
    onError: (error) => {
      console.log(error)
      // logger.error("Something goes wrong..");
    },
  })
  return newOnboarding
}
  
