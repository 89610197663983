import {Option} from '../../ui/interfaces/Option'
import {convertEnumToOptionsWithCleanLabel} from '../utils'

const PerformerBandEnum = {
  DJProducer: 'DJProducer',
  SingerSongwriter: 'SingerSongwriter',
  BandComputerAcousticElectric: 'BandComputerAcousticElectric',
  BandAcousticElectric: 'BandAcousticElectric',
}

type PerformerBandEnumType = typeof PerformerBandEnum[keyof typeof PerformerBandEnum]

function getPerformerBandTypes(): PerformerBandEnumType[] {
  const bands = []
  bands.push(PerformerBandEnum.DJProducer)
  bands.push(PerformerBandEnum.SingerSongwriter)
  bands.push(PerformerBandEnum.BandComputerAcousticElectric)
  bands.push(PerformerBandEnum.BandAcousticElectric)
  return bands
}
function getPerformerBandEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(PerformerBandEnum, printPerformerBand)
}
function getPerformerBandOptions(value: PerformerBandEnumType): Option[] {
  const bandOptions: Option[] = []
  bandOptions.push({
    value: PerformerBandEnum.DJProducer,
    label: `${printPerformerBand(PerformerBandEnum.DJProducer)}`,
  })
  bandOptions.push({
    value: PerformerBandEnum.SingerSongwriter,
    label: `${printPerformerBand(PerformerBandEnum.SingerSongwriter)}`,
  })
  bandOptions.push({
    value: PerformerBandEnum.BandComputerAcousticElectric,
    label: `${printPerformerBand(PerformerBandEnum.BandComputerAcousticElectric)}`,
  })
  bandOptions.push({
    value: PerformerBandEnum.BandAcousticElectric,
    label: `${printPerformerBand(PerformerBandEnum.BandAcousticElectric)}`,
  })
  return bandOptions
}

function printPerformerBand(value: string): string {
  switch (value) {
    case PerformerBandEnum.DJProducer:
      return 'DJ/Producer'
    case PerformerBandEnum.SingerSongwriter:
      return 'Singer-Songwriter, individual or duo'
    case PerformerBandEnum.BandComputerAcousticElectric:
      return 'Band (3-6 members)'
    case PerformerBandEnum.BandAcousticElectric:
      return 'Band (7+ members)'
    default:
      return ''
  }
}

const performerBandTypes = Object.keys(PerformerBandEnum)

export {
  getPerformerBandTypes,
  printPerformerBand,
  performerBandTypes,
  PerformerBandEnum,
  getPerformerBandOptions,
  getPerformerBandEnumOptions
}
