import {FC, ReactNode} from 'react'
import Modal from '../Modal/Modal'
import {Button} from '..'
import {BootstrapUI} from '../../../domain/enums'
//import {TrfScopeWorkSchema} from '../../../../../domain/models/validations'

interface Props {
  isOpen: boolean
  setIsOpen: any
  children: ReactNode
  title: string
  onClose: any
  onProceed: any
}

const PopUp: FC<Props> = ({
  setIsOpen,
  isOpen,
  children,
  title,
  onClose: onClose,
  onProceed: onProceed,
}) => {
  return (
    <>
      <Modal
        id='popup-notification-form'
        title={title}
        visible={isOpen}
        buttons={
          <>
            <Button value='Okay' onClick={onProceed} type='button' theme={BootstrapUI.Dark} />
            <Button value='Close' onClick={onClose} type='button' theme={BootstrapUI.Secondary} />
          </>
        }
        onClose={() => console.log('')}
      >
        {children}
      </Modal>
    </>
  )
}

export default PopUp
