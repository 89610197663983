enum EthnicIdentityEnum {
  SpeakFluentFrenchEnglish = 'Speak fluent French as well as English.',
  // SpeakAnotherLanguage = 'Speak another language.',
  FemaleOperated = 'We are female-owned and operated.', // TODO add field to DB
  IdentifyAsLGBTQ2SPlus = 'Identify as LGBTQ2S+.',
  // IdentifyAsFemaleNonBinary = 'Identify as female or non-binary.',
  IdentifyAsRegisteredVeteran = 'Me, or someone in my group is a registered veteran.',
  UnderAgeOfNineteen = 'Me, or someone in my group is under the age of 19.',
  // OverAgeOfSixtyFive = 'Me, or someone in my group is over the age of 65.',
  IdentifyAsIndigenousMetisFirstNations = 'Identify as Indigenous, Metis, or First Nations.',
  IdentifyAsBlackHispanicLatino = 'Identify as Black, Hispanic, POC.',
}

function printEthnicIdentity(value: EthnicIdentityEnum): string {
  switch (value) {
    case EthnicIdentityEnum.SpeakFluentFrenchEnglish:
      return 'Speak fluent French as well as English.'
    // case EthnicIdentityEnum.SpeakAnotherLanguage:
    //   return 'Speak another language.'
    case EthnicIdentityEnum.FemaleOperated:
      return 'We are female-owned and operated.'
    case EthnicIdentityEnum.IdentifyAsLGBTQ2SPlus:
      return 'Identify as LGBTQ2S+.'
    // case EthnicIdentityEnum.IdentifyAsFemaleNonBinary:
    //   return 'Identify as female or non-binary.'
    case EthnicIdentityEnum.IdentifyAsRegisteredVeteran:
      return 'Me, or someone in my group is a registered veteran.'
    case EthnicIdentityEnum.UnderAgeOfNineteen:
      return 'Me, or someone in my group is under the age of 19.'
    // case EthnicIdentityEnum.OverAgeOfSixtyFive:
    //   return 'Me, or someone in my group is over the age of 65.'
    case EthnicIdentityEnum.IdentifyAsIndigenousMetisFirstNations:
      return 'Identify as Indigenous, Metis, or First Nations.'
    case EthnicIdentityEnum.IdentifyAsBlackHispanicLatino:
      return 'Identify as Black, Hispanic, POC.'
    default:
      return ''
  }
}

export {EthnicIdentityEnum, printEthnicIdentity}
