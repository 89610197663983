import { convertEnumToOptionsWithCleanLabel } from "../utils"

enum VenueStageSuppliesEnum {
  ARaisedStage = 'ARaisedStage',
  SoundSystem = 'SoundSystem',
  Monitors = 'Monitors',
  MicrophonesAndMicStands = 'MicrophonesAndMicStands',
  StageLighting = 'StageLighting',
  ASoundAndLightingTechnician = 'ASoundAndLightingTechnician',
  AStageManager = 'AStageManager',
}

type VenueStageSuppliesType = typeof VenueStageSuppliesEnum[keyof typeof VenueStageSuppliesEnum]

function getVenueStageSuppliesTypes(): VenueStageSuppliesType[] {
  return Object.values(VenueStageSuppliesEnum)
}

function getVenueStageSuppliesEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(VenueStageSuppliesEnum, printVenueStageSupplies)
}

function printVenueStageSupplies(value: VenueStageSuppliesEnum): string | undefined {
  switch (value) {
    case VenueStageSuppliesEnum.ARaisedStage:
      return 'A raised stage'
    case VenueStageSuppliesEnum.SoundSystem:
      return 'Sound System'
    case VenueStageSuppliesEnum.Monitors:
      return 'Monitors'
    case VenueStageSuppliesEnum.MicrophonesAndMicStands:
      return 'Microphones and Mic Stands'
    case VenueStageSuppliesEnum.StageLighting:
      return 'Stage Lighting'
    case VenueStageSuppliesEnum.ASoundAndLightingTechnician:
      return 'A Sound and Lighting Technician'
    case VenueStageSuppliesEnum.AStageManager:
      return 'A Stage Manager'
    default:
      return ''
  }
}

export {getVenueStageSuppliesTypes, printVenueStageSupplies, VenueStageSuppliesEnum, getVenueStageSuppliesEnumOptions}
