import {convertEnumToOptionsWithCleanLabel} from '../utils'

const TeacherAvailableTimeEnum = {
  Morning: 'Morning',
  Afternoon: 'Afternoon',
  Evening: 'Evening',
}

type TeacherAvailableTimeEnum =
  typeof TeacherAvailableTimeEnum[keyof typeof TeacherAvailableTimeEnum]

function getTeacherAvailableTime(value: TeacherAvailableTimeEnum): TeacherAvailableTimeEnum[] {
  const output = []
  output.push(TeacherAvailableTimeEnum.Morning)
  output.push(TeacherAvailableTimeEnum.Afternoon)
  output.push(TeacherAvailableTimeEnum.Evening)
  return output
}

function printTeacherAvailableTime(value: TeacherAvailableTimeEnum): string {
  switch (value) {
    case TeacherAvailableTimeEnum.Morning:
      return 'Morning'
    case TeacherAvailableTimeEnum.Afternoon:
      return 'Afternoon'
    case TeacherAvailableTimeEnum.Evening:
      return 'Evening'
    default:
      return ''
  }
}

function getTeacherAvailableTimeEnumEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(TeacherAvailableTimeEnum, printTeacherAvailableTime)
}

export {getTeacherAvailableTime, printTeacherAvailableTime, TeacherAvailableTimeEnum, getTeacherAvailableTimeEnumEnumOptions}
