import { isEmpty } from "../utils"

const UserTypeEnum = {
  Artist: 'Artist',
  Agent: 'Agent',
  Venue: 'Venue',
}

type UserType = typeof UserTypeEnum[keyof typeof UserTypeEnum]

function getUserTypes(): UserType[] {
  const output: UserType[] = []
  output.push(UserTypeEnum.Artist)
  output.push(UserTypeEnum.Agent)
  output.push(UserTypeEnum.Venue)
  return output
}

// function getArtistOptions(): { id: UserType; label: string }[] {
//   const output: { id: UserType; label: string }[] = [];
//   output.push({ id: artistEnum.PerformerBand, label: printArtist(artistEnum.PerformerBand) });
//   output.push({ id: artistEnum.SessionArtistInstrumentals, label: printArtist(artistEnum.SessionArtistInstrumentals) });
//   output.push({ id: artistEnum.RecordProducerStudioEngineer, label: printArtist(artistEnum.RecordProducerStudioEngineer) });
//   output.push({ id: artistEnum.TeacherClinician, label: printArtist(artistEnum.TeacherClinician) });
//   return output;
// }

const UserTypes = Object.keys(UserTypeEnum) as UserType[]

// function printArtist(value: UserType): string {
//   switch (value) {
//     case artistEnum.PerformerBand:
//       return 'Performer/Band';
//     case artistEnum.SessionArtistInstrumentals:
//       return 'Session Artist/Instrumentals';
//     case artistEnum.RecordProducerStudioEngineer:
//       return 'Record Producer/Studio Engineer';
//     case artistEnum.TeacherClinician:
//       return 'Teacher / Coach / Clinician';
//     default:
//       return '';
//   }

function printUserFromId(value?: number): string {
  if(isEmpty(value)) return "Unspecified"
  switch (value) {
    case 2:
      return 'Artist'
    case 4:
      return 'Agent'
    case 3:
      return 'Venue'
    default:
      return 'Unspecified'
  }
}

export {getUserTypes, printUserFromId, UserTypes}
