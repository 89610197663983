import { required } from 'joi'
import {isEmpty} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'
import DatePicker from 'react-date-picker'

interface Props {
  errors?: any
  label?: string
  name: string
  className?: string
  control: Control<any, any>
  required?: boolean
}

const Default = ({control, name, label, errors, required}: Props) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{required: required}}
        render={({field}) => (
          <div>
            <label htmlFor={name} className='form-label'>
              {label}
            </label>
            <DatePicker
              className={'form-control'}
              onChange={(date: any) => field.onChange(date)}
              value={field.value}
            />
            {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
          </div>
        )}
      />
    </>
  )
}

export default Default
