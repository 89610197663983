import {FC} from 'react'
import {SchoolAcademyLessonsFormProps} from '../../../../management/venue/props'
import {convertEnumToOptionsWithCleanLabel} from '../../../../../../domain/utils'
import {H5, InputTextArea, Title} from '../../../../../components'
import CheckBoxGroup from '../../../../../components/CheckBoxGroup/CheckBoxGroup'
import {SchoolAcademyLessonSpaceEnum, printSchoolAcademyLessonSpace} from '../../../../../../domain/enums/SchoolAcademyLessonSpaceTypeEnum'
import {SchoolAcademyLessonsTypeEnum, printSchoolAcademyLessonsType} from '../../../../../../domain/enums/SchoolAcademyLessonsTypeEnum'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import {
  SchoolAcademyLessonsAdministartionTimesEnum,
  printSchoolAcademyLessonsAdministartionTimes,
} from '../../../../../../domain/enums/SchoolAcademyLessonsAdministartionTimesEnum'
import AddressCard from '../../../partials/AddressCard'
import VenueAdditionalInfoCard from '../../../partials/VenueAdditionalInfoCard'

const SchoolAcademyLessonsForm: FC<SchoolAcademyLessonsFormProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const schoolAcademyLessonSpaceTypeOptions = convertEnumToOptionsWithCleanLabel(
    SchoolAcademyLessonsTypeEnum,
    printSchoolAcademyLessonsType
  )
  const schoolAcademyLessonAdministrationTimeOptions = convertEnumToOptionsWithCleanLabel(
    SchoolAcademyLessonsAdministartionTimesEnum,
    printSchoolAcademyLessonsAdministartionTimes
  )
  return (
    <>
      <div className='col-md-12'>
        <CheckBoxGroup
          name={'subType'}
          label={'Type'}
          // errors={errors.musicVenueType?.message}
          options={schoolAcademyLessonSpaceTypeOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <AddressCard
        id={'salessons-vn-address'}
        label={'Venue Address'}
        control={control}
        errors={errors}
        watch={watch}
        provinceList={provinces}
        provinceLabel={'Province'}
        province={true}
        addressLineOne={true}
        addressLineTwo={true}
        city={true}
        postalCode={true}
        addressPhone={true}
        addressLineOneLabel={'Address Line 1 (required)'}
        addressLineTwoLabel={'Address Line 2'}
        cityLabel={'City (required)'}
        postalCodeLabel={'Postal Code'}
        addressPhoneLabel={'Venue Phone'}
      />

      <div className='col-md-6 mt-5'>
        <H5>The venue's backline and gear</H5>
        <InputTextArea
          control={control}
          rows={4}
          name={'backlineGear'}
          required={false}
          disabled={false}
          label={'What music equipment is supplied?'}
          errors={errors.name?.message}
          className='form-control mt-5'
          placeholder='Drums, amps, pedals, etc'
        />
      </div>

      <VenueAdditionalInfoCard control={control} errors={errors} />
      
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Rental Information</Title>
      </div>
      <div className='col-md-12'>
        <CheckBoxGroup
          name={'recordingStudioRentalTimes'}
          label={'Administration times.'}
          options={schoolAcademyLessonAdministrationTimeOptions}
          control={control}
          className={'mb-5'}
        />
      </div>
      <PayRateInfoCard control={control} errors={errors} rateQuestion='Minimum rate HOUR.' />
    </>
  )
}

export default SchoolAcademyLessonsForm
