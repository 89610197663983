import {BaseResponse} from '../../../../domain/models'
import {
  // ChangePasswordRequest,
  ForgotPasswordRequest,
  LoginRequest,
  // ResetPasswordRequest,
  SignupRequest,
} from '../../../../domain/models/requests/authentication'
import {ChangePasswordRequest} from '../../../../domain/models/requests/authentication/ChangePasswordRequest'
import {VerifyEmailRequest} from '../../../../domain/models/requests/authentication/VerifyEmailRequest'
// import {AuthenticationFilterResponse} from '../../../../domain/models/responses/authentication/AuthenticationFilterResponse'
// import {GetUsersResponse} from '../../../../domain/models/responses/authentication/GetUsersResponse'
import {LoginResponse} from '../../../../domain/models/responses/authentication/LoginResponse'
import {SignUpResponse} from '../../../../domain/models/responses/authentication/SignupResponse'
import {AuthenticationApi} from '../../api/AuthenticationApi'
import {AuthenticationRepository} from './AuthenticationRepository'

export class AuthenticationRepositoryImpl implements AuthenticationRepository {
  api: AuthenticationApi

  constructor(api: AuthenticationApi) {
    this.api = api
  }
  async useForgotPassword(request: ForgotPasswordRequest): Promise<BaseResponse> {
    return this.api.forgotPassword(request)
  }
  async useVerifyEmail(request: VerifyEmailRequest): Promise<BaseResponse> {
    return this.api.verifyEmail(request)
  }
  async useChangePassword(request: ChangePasswordRequest): Promise<BaseResponse> {
    return this.api.changePassword(request)
  }  
  //TODO:: fix this
  async useValidatePasswordReset(request: ChangePasswordRequest): Promise<BaseResponse> {
    return this.api.changePassword(request)
  }
  // async useUpdateUser(request: Omit<SignupRequest, 'id'>): Promise<BaseResponse> {
  //   return this.api.updateUser(request)
  // }
  // async useSignUp(request: Omit<SignupRequest, 'id'>): Promise<BaseResponse> {
  //   return this.api.signUp(request)
  // }
  // async useFilter(): Promise<AuthenticationFilterResponse> {
  //   return this.api.filter()
  // }
  // async useGetById(id: string): Promise<AuthenticationFilterResponse> {
  //   return this.api.getById(id)
  // }
  async useRefreshToken(): Promise<LoginResponse> {
    return this.api.getRefreshToken()
  }
  async useLogin(loginRequest: Omit<LoginRequest, 'id'>): Promise<LoginResponse> {
    return this.api.login(loginRequest)
  }
}
