import {FooterLink} from '../interfaces/FooterLink'

export const DefaultLinks: FooterLink[] = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Glossary',
    to: '/p/glossary',
  },
  {
    label: 'Help',
    to: '/p/help',
  },
  {
    label: 'Terms & Conditions',
    to: '/p/terms-and-conditions',
  },
  {
    label: 'Privacy Policy',
    to: '/p/privacy-policy',
  },
]
export const CopyrightFootNote = "   Version. 1.0.0. © "+ new Date().getFullYear() +" Unionstation Canada. All Rights Reserved."
