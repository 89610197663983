import {FC} from 'react'
import InputText from '../../../components/InputText/InputText'
import {AutoComplete, RenderWhenGrid, Title} from '../../../components'
import {
  convertEnumToOptions,
  getRequiredValidation,
  intToNumberArray,
  mapOptionsToNumberArray,
  mapToOptionsList,
} from '../../../../domain/utils'
import {YesNoOnlyEnum} from '../../../../domain/enums/YesNoEnum'
import {Option} from '../../../interfaces/Option'
import {useCityGetFilter} from '../../../../domain/usecase/location/useLocationGetFilter'
import {LocationRepositoryImpl} from '../../../../infrastructure/remote/repositories/location/LocationRepositoryImpl'
import {LocationApi} from '../../../../infrastructure/remote/api/LocationApi'

export interface Props {
  id: any
  label: string
  control: any
  errors: any
  watch: any
  provinceList: any
  province?: boolean
  addressLineOne?: boolean
  addressLineTwo?: boolean
  city?: boolean
  postalCode?: boolean
  addressPhone?: boolean
  provinceLabel: string
  addressLineOneLabel?: string
  addressLineTwoLabel?: string
  cityLabel?: string
  postalCodeLabel?: string
  addressPhoneLabel?: string
}

const AddressCard: FC<Props> = ({
  id,
  control,
  errors,
  label,
  watch,
  provinceList,
  province,
  addressLineOne = true,
  addressLineTwo = true,
  city = true,
  postalCode = true,
  addressPhone = true,
  provinceLabel,
  addressLineOneLabel,
  addressLineTwoLabel,
  cityLabel,
  postalCodeLabel,
  addressPhoneLabel,
}) => {
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const cityLocationFilter = useCityGetFilter(new LocationRepositoryImpl(new LocationApi()))
  const selectedProvinceIds = watch('addressProvince')
  const getCities = cityLocationFilter(intToNumberArray(selectedProvinceIds))
  const allProvinces = mapToOptionsList(provinceList)

  return (
    <>
      <div className='row'>
        <div className='col-sm-12'>
          <Title type={'xxs'}>{label}</Title>
        </div>
        <RenderWhenGrid visible={addressLineOne}>
          <div className='col-md-6'>
            <InputText
              control={control}
              type='text'
              required={false}
              disabled={false}
              name={'addressLineOne'}
              label={`${addressLineOneLabel}`}
              // errors={errors[`addressLineOne`].message}
              className='form-control mt-5'
            />
          </div>
        </RenderWhenGrid>
        <RenderWhenGrid visible={addressLineTwo}>
          <div className='col-md-6'>
            <InputText
              control={control}
              type='text'
              required={false}
              disabled={false}
              name={'addressLineTwo'}
              label={`${addressLineTwoLabel}`}
              // errors={errors[`addressLineTwo`].message}
              className='form-control mt-5'
            />
          </div>
        </RenderWhenGrid>
        <RenderWhenGrid visible={province}>
        <div className='col-md-6'>
          <AutoComplete
            label={provinceLabel}
            control={control}
            options={allProvinces}
            name={'addressProvince'}
            required={true}
            className=''
            // errors={getRequiredValidation(errors['addressProvince'])}
            getOptionLabel={function (option: Option): string {
              return option.label
            }}
            getOptionValue={function (option: Option): string {
              return option.value
            }}
          />
          </div>
        </RenderWhenGrid>
        <RenderWhenGrid visible={city}>
          <div className='col-md-6'>
            <AutoComplete
              label={cityLabel}
              control={control}
              options={mapToOptionsList(getCities.data?.data)}
              name={'addressCity'}
              required={true}
              errors={getRequiredValidation(errors['addressCity'])}
              getOptionLabel={function (option: Option): string {
                return option.label
              }}
              getOptionValue={function (option: Option): string {
                return option.value
              }}
            />
          </div>
        </RenderWhenGrid>

        <RenderWhenGrid visible={postalCode}>
          <div className='col-md-6'>
            <InputText
              type='text'
              control={control}
              errors={errors.postalCode?.message}
              required={false}
              disabled={false}
              name={'postalCode'}
              label={`${postalCodeLabel}`}
              className='form-control'
            />
          </div>
        </RenderWhenGrid>
        <RenderWhenGrid visible={addressPhone}>
          <div className='col-md-6'>
            <InputText
              type='text'
              control={control}
              errors={errors.addressPhone?.message}
              required={false}
              disabled={false}
              name={'addressPhone'}
              label={`${addressPhoneLabel}`}
              className='form-control'
            />
          </div>
        </RenderWhenGrid>
      </div>
    </>
  )
}

export default AddressCard
