import {convertEnumToOptionsWithCleanLabel} from '../utils'

const TeacherClinicianTypeEnum = {
  FullTimeInstructor: 'FullTimeInstructor',
  SubstituteTeacher: 'SubstituteTeacher',
  ClinicianOrMasterClass: 'ClinicianOrMasterClass',
  Mobile: 'Mobile',
  Online: 'Online',
  VocalCoach: 'VocalCoach',
  PerformanceCoach:'PerformanceCoach',
  ConductorAdjudicator:'ConductorAdjudicator'
}

type TeacherClinicianTypeEnumType =
  typeof TeacherClinicianTypeEnum[keyof typeof TeacherClinicianTypeEnum]

function getTeacherClinicianTypeEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(TeacherClinicianTypeEnum, printTeacherClinicianType)
}

function getTeacherClinicianType(
  value: TeacherClinicianTypeEnumType
): TeacherClinicianTypeEnumType[] {
  const output = []
  output.push(TeacherClinicianTypeEnum.FullTimeInstructor)
  output.push(TeacherClinicianTypeEnum.SubstituteTeacher)
  output.push(TeacherClinicianTypeEnum.ClinicianOrMasterClass)
  output.push(TeacherClinicianTypeEnum.Mobile)
  output.push(TeacherClinicianTypeEnum.Online)
  output.push(TeacherClinicianTypeEnum.VocalCoach)
  output.push(TeacherClinicianTypeEnum.PerformanceCoach)
  output.push(TeacherClinicianTypeEnum.ConductorAdjudicator)
  return output
}



function printTeacherClinicianType(value: TeacherClinicianTypeEnumType): string {
  switch (value) {
    case TeacherClinicianTypeEnum.FullTimeInstructor:
      return 'Full Time Music Teacher'
    case TeacherClinicianTypeEnum.SubstituteTeacher:
      return 'Substitute Teacher'
    case TeacherClinicianTypeEnum.VocalCoach:
      return 'Vocal Coach'
    case TeacherClinicianTypeEnum.PerformanceCoach:
      return 'Performance Coach'
    case TeacherClinicianTypeEnum.ClinicianOrMasterClass:
      return 'Clinician or Master Class'
    case TeacherClinicianTypeEnum.ConductorAdjudicator:
      return 'Conductor / Adjudicator' 
    case TeacherClinicianTypeEnum.Mobile:
      return 'Outreach / Mobile / In-Home'
    case TeacherClinicianTypeEnum.Online:
      return 'Online'
    default:
      return 'N/A'
  }
}

export {getTeacherClinicianType, printTeacherClinicianType, TeacherClinicianTypeEnum, getTeacherClinicianTypeEnumOptions}
