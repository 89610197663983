enum SchoolAcademyLessonsTypeEnum {
  MusicForYoungChildren = 'MusicForYoungChildren',
  SuzukiSchool = 'SuzukiSchool',
  RoyalConservatoryOfMusic = 'RoyalConservatoryOfMusic',
  NonprofitMusicSchool = 'NonprofitMusicSchool',
  LocalGeneralizedMusicSchool = 'LocalGeneralizedMusicSchool',
  PrecollegeInstruction = 'PrecollegeInstruction',
  CollegeMusicPrograms = 'CollegeMusicPrograms',
  UniversityAndBachelorDegreePrograms = 'UniversityAndBachelorDegreePrograms',
  MastersAndDoctoratePrograms = 'MastersAndDoctoratePrograms',
  OnlineMusicPrograms = 'OnlineMusicPrograms',
}

type SchoolAcademyLessonsTypeType =
  typeof SchoolAcademyLessonsTypeEnum[keyof typeof SchoolAcademyLessonsTypeEnum]

function getSchoolAcademyLessonsTypeTypes(): SchoolAcademyLessonsTypeType[] {
  return Object.values(SchoolAcademyLessonsTypeEnum)
}

function printSchoolAcademyLessonsType(value: SchoolAcademyLessonsTypeEnum): string | undefined {
  switch (value) {
    case SchoolAcademyLessonsTypeEnum.MusicForYoungChildren:
      return 'Music For Young Children'
    case SchoolAcademyLessonsTypeEnum.SuzukiSchool:
      return 'Suzuki School'
    case SchoolAcademyLessonsTypeEnum.RoyalConservatoryOfMusic:
      return 'Royal Conservatory of Music'
    case SchoolAcademyLessonsTypeEnum.NonprofitMusicSchool:
      return 'Non-Profit Music School'
    case SchoolAcademyLessonsTypeEnum.LocalGeneralizedMusicSchool:
      return 'Local Generalized Music School'
    case SchoolAcademyLessonsTypeEnum.PrecollegeInstruction:
      return 'Pre-College Instruction'
    case SchoolAcademyLessonsTypeEnum.CollegeMusicPrograms:
      return 'College Music Programs'
    case SchoolAcademyLessonsTypeEnum.UniversityAndBachelorDegreePrograms:
      return 'University and Bachelor Degree Programs'
    case SchoolAcademyLessonsTypeEnum.MastersAndDoctoratePrograms:
      return 'Masters and Doctorate Programs'
    case SchoolAcademyLessonsTypeEnum.OnlineMusicPrograms:
      return 'Online Music Programs'
    default:
      return ''
  }
}

export {
  getSchoolAcademyLessonsTypeTypes,
  printSchoolAcademyLessonsType,
  SchoolAcademyLessonsTypeEnum,
}
