import { BaseUrl } from ".."

const ApiUrl = `${BaseUrl}/v1`
const ApiUrlV2 = `${BaseUrl}/v2`

export const UserApiRoutes = {
  Login: `${ApiUrl}/user/login`,
  SignUp: `${ApiUrl}/user/signup`,
  ForgotPassword: `${ApiUrl}/user/reset-password`,
  ResetPassword: `${ApiUrl}/user/reset-password`,
  ChangePassword: `${ApiUrl}/user/update-password`,
  VerifyEmail: `${ApiUrl}/user/verify-email`,
  Filter: `${ApiUrl}/user/filter`,
  Get: `${ApiUrl}/user`,
  Me: `${ApiUrl}/user/me`,
  RefreshToken: `${ApiUrl}/user/auth/refresh`,
  NewOnboarding: `${ApiUrl}/user/onboarding`,
  Create: `${ApiUrl}/user`,
  Switch: `${ApiUrl}/user/auth/switch`,
  Logout: `${ApiUrl}/user/auth/logout`,
  Delete: `${ApiUrl}/user`,
  Update: `${ApiUrl}/user`,
  ResetAccount: `${ApiUrl}/user/reset-onboarding`,
  GetFiltered: `${ApiUrl}/user/filter`,
}

export const ArtistApiRoutes = {
  Create: `${ApiUrl}/artist`,
  Delete: `${ApiUrlV2}/artist/delete`,
  Update: `${ApiUrlV2}/artist/update`,
  GetFiltered: `${ApiUrl}/artist/list/page/`,
}

export const AgentApiRoutes = {
  Create: `${ApiUrl}/agent`,
  Delete: `${ApiUrl}/agent`,
  Update: `${ApiUrl}/agent`,
  GetFiltered: `${ApiUrl}/agent/list/page/`,
}


export const VenueApiRoutes = {
  Post: `${ApiUrl}/venue`,
  Delete: `${ApiUrl}/venue`,
  Put: `${ApiUrl}/venue`,
  GetFiltered: `${ApiUrl}/venue/list/page/`,
}

export const LocationApiRoutes = {
  Post: `${ApiUrl}/location`,
  Delete: `${ApiUrl}/location`,
  Put: `${ApiUrl}/location`,
  FIlter: `${ApiUrl}/location/filter`,
}

export const MiscApiRoutes = {
  Post: `${ApiUrl}/misc`,
  Delete: `${ApiUrl}/misc`,
  Put: `${ApiUrl}/misc`,
  GetFiltered: `${ApiUrl}/listing/list/page/`,
}

export const ConnectApiRoutes = {
  Create: `${ApiUrl}/connect/create`,
  Delete: `${ApiUrl}/connect`,
  Update: `${ApiUrl}/connect`,
  GetFiltered: `${ApiUrl}/connect/list/page/`,
  GetAllFiltered: `${ApiUrl}/connect/all/filter`,
  GetAllFilteredMatch: `${ApiUrl}/connect/match/filter`,
  GetIncomingRequests: `${ApiUrl}/connect/incoming-requests/filter`,
  GetAllConnectOutReach:  `${ApiUrl}/connect/outreach/filter`,
  GetConnectStatus:  `${ApiUrl}/connect/request/activity/`,
  UpdateConnectMatchStatus:  `${ApiUrl}/connect/offer/match/update`,
  OfferBidResponse:  `${ApiUrl}/connect/offer/bids/respond`,
  MakePayment:  `${ApiUrl}/connect/make-payment`,
  UpdatePaymentStatus:  `${ApiUrl}/connect/update-payment-status`,
  SaveConnectShortlist:  `${ApiUrl}/connect/shortlist`,
  BookConnectRequest:  `${ApiUrl}/connect/book`,
  BookAvailabilityConfirmationConnectRequest:  `${ApiUrl}/connect/book/confirmation`,
}
export const PaymentApiRoutes = {
  CreateIntent: `${ApiUrl}/payment/create/intent`,
}