import Select, {ActionMeta, MultiValue} from 'react-select'
import {Control, Controller} from '../../../infrastructure/deps/react-form'
import { isEmpty } from '../../../domain/utils'

interface Option {
  value: string
  label: string
}

interface Props<T> {
  name: string
  className?: string
  label?: string
  options: T[]
  control: Control<any, any>
  getOptionLabel: (option: T) => string
  getOptionValue: (option: T) => string
  required?: boolean,
  errors?: any
}

const AutoCompleteMultiple = ({
  name,
  className,
  control,
  label,
  options,
  getOptionLabel,
  getOptionValue,
  required = false,
  errors
}: Props<Option>) => {
  const autoCompleteOptions: Option[] = options.map((option) => ({
    value: getOptionValue(option),
    label: getOptionLabel(option),
  }))

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({field: {onChange, value}}) => (
        <div>
          <label htmlFor={name} className='form-label'>
            {label}
          </label>
          <Select
            isMulti
            name={name}
            // className={className}
            className='form-control react-select-styled react-select-transparent'
            classNamePrefix='react-select'

            options={autoCompleteOptions}
            value={autoCompleteOptions.filter((elem) => {
              return value?.some((ele: Option) => {
                return ele.value === elem.value
              })
            })}
            onChange={(newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
              if (newValue) {
                const selectedOptions = Array.isArray(newValue)
                  ? newValue.map((option) => option)
                  : []
                console.log(selectedOptions)
                onChange(selectedOptions)
              } else {
                onChange([])
              }
            }}
          />
          {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
        </div>
      )}
    />
  )
}

export default AutoCompleteMultiple
