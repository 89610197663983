/* eslint-disable jsx-a11y/anchor-is-valid */
import {P, Title} from '../../../components'

export default function PrivacyPolicy() {
  return (
    <div>
      <div className='text-center'>
        <Title type='xxs'>Privacy Policy Page</Title>
        unionstationcanada.com (the "Site") is owned and operated by Union Station Canada.
        <h2 className='mt-10 text-2xl '>Purpose</h2>
        The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of
        the following:
        <p>1. The personal data we will collect;</p>
        <p>2. Use of collected data;</p>
        <p>3. Who has access to the data collected; and</p>
        <p>4. The rights of Site users.</p>
        This Privacy Policy applies in addition to the terms and conditions of our Site.
        <h2 className='mt-10 text-2xl text-center'>Consent</h2>
        By using our Site users agree that they consent to:
        <p>1. The conditions set out in this Privacy Policy; and</p>
        <p>2. The collection, use, and retention of the data listed in this Privacy Policy.</p>
        <h2 className='mt-10 text-2xl text-center'>Personal Data We Collect</h2>
        We only collect data that helps us achieve the purpose set out in this Privacy Policy. We
        will not collect any additional data beyond the data listed below without notifying you
        first.
        <h2 className='mt-10 text-2xl text-center'>Data Collected Automatically</h2>
        When you visit and use our Site, we may automatically collect and store the following
        information:
        <p>1. User input data.</p>
        <h2 className='mt-10 text-2xl text-center'>Data Collected in a Non-Automatic Way</h2>
        We may also collect the following data when you perform certain functions on our Site:
        <p>1. First and last name;</p>
        <p>2. Age;</p>
        <p>3. Date of birth;</p>
        <p>4. Sex;</p>
        <p>5. Email address;</p>
        <p>6. Phone number;</p>
        <p>7. Address;</p>
        <p>8. Payment information;</p>
        <p>9. Auto fill data;</p>
        <p>10. Contact Information; and</p>
        <p>11. Data relevant to Hiring Offers and Referrals.</p>
        This data may be collected using the following methods:
        <p>1. Creating an account.</p>
        <h2 className='mt-10 text-2xl text-center'>How We Use Personal Data</h2>
        Data collected on our Site will only be used for the purposes specified in this Privacy
        Policy or indicated on the relevant pages of our Site. We will not use your data beyond what
        we disclose in this Privacy Policy. The data we collect automatically is used for the
        following purposes:
        <p>1. Referrals; and</p>
        <p>2. Music services.</p>
        The data we collect when the user performs certain functions may be used for the following
        purposes:
        <p>1. Communication.</p>
        <h2 className='mt-10 text-2xl text-center'>Who We Share Personal Data With</h2>
        <h3 className='text-xl text-center'>Employees</h3>
        We may disclose user data to any member of our organization who reasonably needs access to
        user data to achieve the purposes set out in this Privacy Policy.
        <h3 className='text-xl text-center'>Third Parties</h3>
        We may share user data with the following third parties:
        <p>1. Other users of Union Station.</p>
        We may share the following user data with third parties:
        <p>1. Contact Information, Service Information, and Event Information.</p>
        We may share user data with third parties for the following purposes:
        <p>1. Referral of Music Services.</p>
        Third parties will not be able to access user data beyond what is reasonably necessary to
        achieve the given purpose.
        <h3 className='text-xl text-center'>Other Disclosures</h3>
        We will not sell or share your data with other third parties, except in the following cases:
        <p>1. If the law requires it;</p>
        <p>2. If it is required for any legal proceeding;</p>
        <p>3. To prove or protect our legal rights; and</p>
        <p>
          4. To buyers or potential buyers of this company in the event that we seek to sell the
          company.
        </p>
        If you follow hyperlinks from our Site to another Site, please note that we are not
        responsible for and have no control over their privacy policies and practices.
        <h2 className='mt-10 text-2xl text-center'>How Long We Store Personal Data</h2>
        User data will be stored until the purpose the data was collected for has been achieved. You
        will be notified if your data is kept for longer than this period. How We Protect Your
        Personal Data In order to protect your security, we use the strongest available browser
        encryption and store all of our data on servers in secure facilities. All data is only
        accessible to our employees. Our employees are bound by strict confidentiality agreements
        and a breach of this agreement would result in the employee's termination. While we take all
        reasonable precautions to ensure that user data is secure and that users are protected,
        there always remains the risk of harm. The Internet as a whole can be insecure at times and
        therefore we are unable to guarantee the security of user data beyond what is reasonably
        practical.
        <h2 className='mt-10 text-2xl text-center'>Children</h2>
        We do not knowingly collect or use personal data from children under 13 years of age. If we
        learn that we have collected personal data from a child under 13 years of age, the personal
        data will be deleted as soon as possible. If a child under 13 years of age has provided us
        with personal data their parent or guardian may contact our privacy officer.
        <h2 className='mt-10 text-2xl text-center'>Modifications</h2>
        This Privacy Policy may be amended from time to time in order to maintain compliance with
        the law and to reflect any changes to our data collection process. When we amend this
        Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We
        recommend that our users periodically review our Privacy Policy to ensure that they are
        notified of any updates. If necessary, we may notify users by email of changes to this
        Privacy Policy.
      </div>
    </div>
  )
}
