import {useQuery} from '../../../infrastructure/deps/react-query'
import {LocationRepositoryImpl} from '../../../infrastructure/remote/repositories/location/LocationRepositoryImpl'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import {LocationFilter} from '../../entities/filters/LocationFilter'
import {ResponseData} from '../../models/responses/ResponseData'

export const useLocationGetFilter = (repository: LocationRepositoryImpl) => {
  const useFilter = (locationFilter: LocationFilter) =>
    useQuery<ResponseData>({
      queryKey: ['getLocations'],
      queryFn: () => repository.useGetFilter(locationFilter),
      onSuccess(response) {
        // console.log(response.data)
        return response.data
      },
      onError: (error) => {
        console.log(error)
      },
    })
  return useFilter
}

export const useCityGetFilter = (repository: LocationRepositoryImpl) => {
  const useFilter = (provinceId: number[]) =>
    useQuery<ResponseData>({
      queryKey: ['getLocations', provinceId],
      queryFn: () => repository.useGetCityFilter(provinceId),
      onSuccess(response) {
        // console.log(response.data)
        return response.data
      },
      onError: (error) => {
        console.log(error)
      },
    })
  return useFilter
}
