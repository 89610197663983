import {DynamicSideBarItem} from './DynamicSideBarItem'

export const DropDownMenuOptions: DynamicSideBarItem[] = [
  {
    name: 'Home',
    route: '/dashboard',
  },
  {
    name: 'Manage',
    route: '#',
    subRoutes: [
      {
        name: 'Artists',
        route: '/manage/artists',
      },
      {
        name: 'Venues',
        route: '/manage/venues',
      },
      {
        name: 'Agents',
        route: '/manage/agents',
      },
      {
        name: 'Users',
        route: '/manage/users',
      },
      {
        name: 'Connects',
        route: '/manage/connects',
      },
      {
        name: 'Verifications',
        route: '/manage/verifications',
      },
    ],
  },
  {
    name: 'Manage Account',
    route: '/manage/accounts',
  },
  {
    name: 'Connect',
    route: '#',
    subRoutes: [
      {
        name: 'New Connect',
        route: '/dashboard',
      },
      {
        name: 'Incoming Requests',
        route: '/connect/incoming-requests',
      },
      {
        name: 'Outreach',
        route: '/connect/outreach',
      },
    ],
  },
]
