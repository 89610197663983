import {useQueryClient, useMutation, useQuery} from '../../../infrastructure/deps/react-query'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import {SwitchUserRequest} from '../../models/requests/user/SwitchUserRequest'
import {UserRequest} from '../../models/requests/user/UserRequest'
import {ResponseData} from '../../models/responses/ResponseData'

export const useUserLogout = (repository: UserRepositoryImpl) => {
  const useLogout = () =>
    useQuery<ResponseData>({
      queryKey: ['logoutUser'],
      queryFn: () => repository.useUserLogout(),
      onSuccess(response) {
        // console.log(response.data)
        return response.data
      },
      onError: (error) => {
        console.log(error)
      },
    })
  return useLogout
}
