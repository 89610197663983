import {convertEnumToOptionsWithCleanLabel} from '../utils'

enum BookingAgentTalentBuyerTypeEnum {
  BookingAgent = 'BookingAgent',
  TalentBuyer = 'TalentBuyer',
  Contractor = 'Contractor',
}

type BookingAgentTalentBuyerTypeType =
  typeof BookingAgentTalentBuyerTypeEnum[keyof typeof BookingAgentTalentBuyerTypeEnum]

function getBookingAgentTalentBuyerTypeTypes(): BookingAgentTalentBuyerTypeType[] {
  return Object.values(BookingAgentTalentBuyerTypeEnum)
}

function getBookingAgentTalentBuyerTypeOptions() {
  return convertEnumToOptionsWithCleanLabel(
    BookingAgentTalentBuyerTypeEnum,
    printBookingAgentTalentBuyerType
  )
}

function printBookingAgentTalentBuyerType(
  value: BookingAgentTalentBuyerTypeEnum
): string | undefined {
  switch (value) {
    case BookingAgentTalentBuyerTypeEnum.BookingAgent:
      return 'Booking Agent'
    case BookingAgentTalentBuyerTypeEnum.TalentBuyer:
      return 'Talent Buyer'
    case BookingAgentTalentBuyerTypeEnum.Contractor:
      return 'Contractor'
    default:
      return ''
  }
}

export {
  getBookingAgentTalentBuyerTypeTypes,
  printBookingAgentTalentBuyerType,
  BookingAgentTalentBuyerTypeEnum,
  getBookingAgentTalentBuyerTypeOptions,
}
