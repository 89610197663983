import { convertEnumToOptionsWithCleanLabel } from "../utils"

enum GenresEnum {
  // Singersongwriter = 'Singersongwriter',
  Pop = 'Pop',
  Folk = 'Folk',
  Country = 'Country',
  Americana = 'Americana',
  Blues = 'Blues',
  Roots = 'Roots',
  Alternative = 'Alternative',
  HardRock = 'HardRock',
  Rock = 'Rock',
  Jazz = 'Jazz',
  Indie = 'Indie',
  ClassicRock = 'ClassicRock',
  Abilly = 'Abilly',
  Reggae = 'Reggae',
  Ska = 'Ska',
  Funkn = 'Funkn',
  Top40 = 'Top40',
  Latin = 'Latin',
  HipHop = 'HipHop',
  Rap = 'Rap',
  Rnb = 'Rnb',
  Soul = 'Soul',
  Gospel = 'Gospel',
  Christian = 'Christian',
  Fusion = 'Fusion',
  Bluegrass = 'Bluegrass',
  Experimental = 'Experimental',
  Synth = 'Synth',
  Surf = 'Surf',
  Easy = 'Easy',
  Disco = 'Disco',
  Dance = 'Dance',
  Prog = 'Prog',
  Psychedelic = 'Psychedelic',
  Metal = 'Metal',
  Thrash = 'Thrash',
  Hardcore = 'Hardcore',
  Punk = 'Punk',
  Grunge = 'Grunge',
  Celtic = 'Celtic',
  World = 'World',
  African = 'African',
  Children = 'Children',
  Classical = 'Classical',
  MusicalTheatre = 'MusicalTheatre',
  Choral = 'Choral',
  Instrumental = 'Instrumental',
  FilmGameTvComic = 'FilmGameTvComic',
  SpokenWord = 'SpokenWord',
  Electronic = 'Electronic',
  Djproducer = 'Djproducer',
  CoverDj = 'CoverDj',
  Edm = 'Edm',
  Dubstep = 'Dubstep',
  House = 'House',
  Trap = 'Trap',
  Techno = 'Techno',
  DrumNBass = 'DrumNBass',
  Jungle = 'Jungle',
  Trance = 'Trance',
  Ambient = 'Ambient',
  Indigenous = 'Indigenous',
  Western = 'Western',
  Electroswing = 'Electroswing',
  Religious = 'Religious',
  Wedding = 'Wedding',
  Christmasholiday = 'Christmasholiday',
}

type GenresType = typeof GenresEnum[keyof typeof GenresEnum]

function getGenresTypes(): GenresType[] {
  return Object.values(GenresEnum)
}

function getGenresEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(GenresEnum, printGenres)
}
function printGenres(value: GenresEnum): string | undefined {
  switch (value) {
    // case GenresEnum.Singersongwriter:
    //   return 'Singer-Songwriter'
    case GenresEnum.Pop:
      return 'Pop'
    case GenresEnum.Folk:
      return 'Folk'
    case GenresEnum.Country:
      return 'Country'
    case GenresEnum.Americana:
      return 'Americana'
    case GenresEnum.Blues:
      return 'Blues'
    case GenresEnum.Roots:
      return 'Roots'
    case GenresEnum.Alternative:
      return 'Alternative'
    case GenresEnum.HardRock:
      return 'Hard Rock'
    case GenresEnum.Rock:
      return 'Rock'
    case GenresEnum.Jazz:
      return 'Jazz'
    case GenresEnum.Indie:
      return 'Indie'
    case GenresEnum.ClassicRock:
      return 'Classic Rock'
    case GenresEnum.Abilly:
      return '-abilly'
    case GenresEnum.Reggae:
      return 'Reggae'
    case GenresEnum.Ska:
      return 'Ska'
    case GenresEnum.Funkn:
      return 'Funkn'
    case GenresEnum.Top40:
      return 'Top 40'
    case GenresEnum.Latin:
      return 'Latin'
    case GenresEnum.HipHop:
      return 'Hip Hop'
    case GenresEnum.Rap:
      return 'Rap'
    case GenresEnum.Rnb:
      return 'RnB'
    case GenresEnum.Soul:
      return 'Soul'
    case GenresEnum.Gospel:
      return 'Gospel'
    case GenresEnum.Christian:
      return 'Christian'
    case GenresEnum.Fusion:
      return 'Fusion'
    case GenresEnum.Bluegrass:
      return 'Bluegrass'
    case GenresEnum.Experimental:
      return 'Experimental'
    case GenresEnum.Synth:
      return 'Synth'
    case GenresEnum.Surf:
      return 'Surf'
    case GenresEnum.Easy:
      return 'Easy'
    case GenresEnum.Disco:
      return 'Disco'
    case GenresEnum.Dance:
      return 'Dance'
    case GenresEnum.Prog:
      return 'Prog'
    case GenresEnum.Psychedelic:
      return 'Psychedelic'
    case GenresEnum.Metal:
      return 'Metal'
    case GenresEnum.Thrash:
      return 'Thrash'
    case GenresEnum.Hardcore:
      return 'Hardcore'
    case GenresEnum.Punk:
      return 'Punk'
    case GenresEnum.Grunge:
      return 'Grunge'
    case GenresEnum.Celtic:
      return 'Celtic'
    case GenresEnum.World:
      return 'World'
    case GenresEnum.African:
      return 'African'
    case GenresEnum.Children:
      return 'Children'
    case GenresEnum.Classical:
      return 'Classical'
    case GenresEnum.MusicalTheatre:
      return 'Musical Theatre'
    case GenresEnum.Choral:
      return 'Choral'
    case GenresEnum.Instrumental:
      return 'Instrumental'
    case GenresEnum.FilmGameTvComic:
      return 'Film, Game, TV, Comic'
    case GenresEnum.SpokenWord:
      return 'Spoken Word'
    case GenresEnum.Electronic:
      return 'Electronic'
    case GenresEnum.Djproducer:
      return 'DJ/Producer'
    case GenresEnum.CoverDj:
      return 'Cover DJ'
    case GenresEnum.Edm:
      return 'EDM'
    case GenresEnum.Dubstep:
      return 'Dubstep'
    case GenresEnum.House:
      return 'House'
    case GenresEnum.Trap:
      return 'Trap'
    case GenresEnum.Techno:
      return 'Techno'
    case GenresEnum.DrumNBass:
      return 'Drum n Bass'
    case GenresEnum.Jungle:
      return 'Jungle'
    case GenresEnum.Trance:
      return 'Trance'
    case GenresEnum.Ambient:
      return 'Ambient'
    case GenresEnum.Indigenous:
      return 'Indigenous'
    case GenresEnum.Western:
      return 'Western'
    case GenresEnum.Electroswing:
      return 'Electro-Swing'
    case GenresEnum.Religious:
      return 'Religious'
    case GenresEnum.Wedding:
      return 'Wedding'
    case GenresEnum.Christmasholiday:
      return 'Christmas/Holiday'
    default:
      return ''
  }
}

export {getGenresTypes, printGenres, GenresEnum,getGenresEnumOptions}
