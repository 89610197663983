import {LocationFilter} from '../../../../domain/entities/filters/LocationFilter'
import {BaseResponse} from '../../../../domain/models'
import {OnboardingRequest} from '../../../../domain/models/requests/common/OnboardingRequest'
import {UserRequest} from '../../../../domain/models/requests/user/UserRequest'
import {ActionResponse} from '../../../../domain/models/responses/ActionResponse'
import {ResponseData} from '../../../../domain/models/responses/ResponseData'
import {LocationApi} from '../../api/LocationApi'
import {UserApi} from '../../api/UserApi'
import {LocationRepository} from './LocationRepository'

export class LocationRepositoryImpl implements LocationRepository {
  api: LocationApi

  constructor(api: LocationApi) {
    this.api = api
  }
  async useGetFilter(filter: LocationFilter): Promise<ResponseData> {
    return this.api.useGetFilter(filter)
  }

  async useGetCityFilter(provinceIds:  number[]): Promise<ResponseData> {
    const filter: LocationFilter = {
      countryId: 1,
      type: 'city',
      provinceId: provinceIds,
    }
    console.log("CITY", filter)
    return this.api.useGetFilter(filter)
  }
}
