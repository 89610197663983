import {convertEnumToOptionsWithCleanLabel} from '../utils'

enum EthnicVenueIdentityEnum {
  IdentifyAsLGBTQ2SPlus = 'IdentifyAsLGBTQ2SPlus',
  WheelChairAccessibale = 'WheelChairAccessibale',
  PointOfContactSpeaksFrenchAsWellAsEng = 'PointOfContactSpeaksFrenchAsWellAsEng',
  WeAreSensoryFriendly = 'WeAreSensoryFriendly',
  WeUseLasersAndFog = 'WeUseLasersAndFog',
}

function printEthnicVenueIdentity(value: EthnicVenueIdentityEnum): string {
  switch (value) {
    case EthnicVenueIdentityEnum.IdentifyAsLGBTQ2SPlus:
      return 'We are a safe space for LGBTQ2S+.'
    case EthnicVenueIdentityEnum.WheelChairAccessibale:
      return 'We are a wheelchair accessible venue..'
    case EthnicVenueIdentityEnum.PointOfContactSpeaksFrenchAsWellAsEng:
      return 'The Point of Contact speaks French as well as English.'
    case EthnicVenueIdentityEnum.WeAreSensoryFriendly:
      return 'We are a sensory friendly environment.'
    case EthnicVenueIdentityEnum.WeUseLasersAndFog:
      return 'We use lasers and fog.'
    default:
      return ''
  }
}

function getEthnicVenueIdentityEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(EthnicVenueIdentityEnum, printEthnicVenueIdentity)
}

export {EthnicVenueIdentityEnum, printEthnicVenueIdentity, getEthnicVenueIdentityEnumOptions}
