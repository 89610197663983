/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useAuth} from '../../../../infrastructure/local/auth/AuthContextProvider'
import {
  ForgotPasswordRequest,
  LoginRequest,
} from '../../../../domain/models/requests/authentication'
import {useLogin} from '../../../../domain/usecase'
import {AuthenticationRepositoryImpl} from '../../../../infrastructure/remote/repositories/authentication/AuthenticationRepositoryImpl'
import {AuthenticationApi} from '../../../../infrastructure/remote/api'
import {HttpStatusCode, ResponseStatus} from '../../../../infrastructure/enums'
import {Auth, User} from '../../../../domain/entities'
import UserMapper from '../../../../domain/mappers/UserMapper'
import {useForm} from '../../../..//infrastructure/deps/react-form'
import {H5, InlineAlert, InputText, P} from '../../../components'
import Button from '../../../components/Button/Button'
import {BootstrapUI} from '../../../../domain/enums'
import {ForgotPasswordSchema} from '../../../../domain/models/validations'
import {joiResolver} from '@hookform/resolvers/joi'
import {isEmptyObject} from '../../../../domain/utils'
import {da} from 'date-fns/locale'
import {useForgotPassword} from '../../../../domain/usecase/authentication/useForgotPassword'
import {useNavigate} from 'react-router-dom'
import PopUp from '../../../components/PopUp/PopUp'

interface IFormInput {
  email: string
}

export function ForgotPassword() {
  const navigate = useNavigate()
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const defaultValues: IFormInput = {
    email: '',
  }

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues,
    resolver: joiResolver(ForgotPasswordSchema),
  })

  const [status, setStatus] = useState('')
  const {setCurrentUser} = useAuth()
  const performPasswordReset = useForgotPassword(
    new AuthenticationRepositoryImpl(new AuthenticationApi())
  )
  const mapper = new UserMapper()

  const onSubmit = (formData: IFormInput) => {
    let request: ForgotPasswordRequest = {
      username: formData.email,
    }
    performPasswordReset.mutate(request, {
      onSuccess: (response) => {
        const {data, status} = response
        if (status === HttpStatusCode.OK) {
          setIsPasswordResetSuccess(true)
        } else {
          setStatus('The account details are incorrect')
        }
      },
      onError: () => {
        setStatus('The account details are incorrect')
      },
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InlineAlert value={status} />
      <div className='mb-4'>
        <InputText
          control={control}
          type='text'
          name={'email'}
          label={'Email'}
          errors={errors.email?.message}
          className='form-control mt-5'
        />
      </div>
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div></div>
        <a href='#' className='link-secondary' onClick={() => navigate('/auth')}>
          Login to my account instead
        </a>
      </div>
      <div className='d-grid mb-10'>
        <Button
          type='submit'
          loading={performPasswordReset.isLoading}
          disabled={!isEmptyObject(errors) || performPasswordReset.isLoading}
          theme={BootstrapUI.Dark}
          value={'Reset Password'}
        />
      </div>
      <PopUp
        isOpen={isPasswordResetSuccess}
        setIsOpen={setIsPasswordResetSuccess}
        title={'Password Reset'}
        onClose={() => navigate('/auth')}
        onProceed={() => navigate('/auth')}
      >
        <H5>A password reset link has been sent to you email.</H5>
        <P>Kindly follow the instructions to regain access to your account.</P>
      </PopUp>
    </form>
  )
}
