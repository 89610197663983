import {FC} from 'react'
import {AgentTypeEnum, printAgent} from '../../../../domain/enums/AgentTypeEnum'
import {YesNoEnum, YesNoOnlyEnum} from '../../../../domain/enums/YesNoEnum'
import {convertEnumToOptions, convertEnumToOptionsWithCleanLabel} from '../../../../domain/utils'
import {RenderWhenGrid} from '../../../components'
import BookingAgentTalentBuyerContractorForm from '../../shared/forms/onboarding/agent/BookingAgentTalentBuyerContractorForm'
import {AgentFormProps} from '../../management/agent/props'
import EventManagerEventTechnicianForm from '../../shared/forms/onboarding/agent/EventManagerEventTechnicianForm'
import PromoterManagementAgentForm from '../../shared/forms/onboarding/agent/PromoterManagementAgentForm'
import ContactCard from '../../shared/partials/ContactCard'
import ToggleButtonGroup from '../../../components/ToggleButtonGroup/ToggleButtonGroup'
import LocationMultiSelectCard from '../../../components/LocationMultiSelectCard/LocationMultiSelectCard'

const AgentOnboardingForm: FC<AgentFormProps> = ({control, errors, watch, provinces}) => {
  const yesNoOptions = convertEnumToOptions(YesNoEnum)
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const agentType = watch('agentType')
  const artistTypeOptions = convertEnumToOptionsWithCleanLabel(AgentTypeEnum, printAgent)
  return (
    <>
      <div className='row'>
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'agentType'}
            label={'What type of agent?'}
            type='exclusive'
            options={artistTypeOptions}
            control={control}
          />
        </div>
        {/* Booking Agent / Talent Buyer / Contractor */}
        <RenderWhenGrid visible={agentType === AgentTypeEnum.BookingAgentTalentBuyerContractor}>
          <BookingAgentTalentBuyerContractorForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>
        {/* Event Manager / Event Technician */}
        <RenderWhenGrid visible={agentType === AgentTypeEnum.EventManagerEventTechnician}>
          <EventManagerEventTechnicianForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>
        {/* Promoter / Management Agent */}
        <RenderWhenGrid visible={agentType === AgentTypeEnum.PromotorManagamentAgent}>
          <PromoterManagementAgentForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>

        <ContactCard
          id={''}
          label='Contact Information'
          control={control}
          errors={errors}
          website={false}
          //firstName={true}
          name={true}
          emailAddress={true}
          phone={true}
          // googlePhotos = false,
          // googleReviews = false,
          // wikipedia = false,
          spotify={false}
          // appleMusic = false,
          // googlePlay = false,
          facebook={false}
          showSocials={false}
          instagram={false}
          youtube={false}
          tiktok={false}

          shareProfile={false}
          representingArtist={false}
          representingLabelOrAgency={false}
        />
      </div>
    </>
  )
}

export default AgentOnboardingForm
