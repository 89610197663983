/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../../../../infrastructure/deps/metronic/v2'
import {CopyrightFootNote, DefaultLinks} from '../../../../constants/FooterConstants'
import {FooterLink} from '../../../../interfaces/FooterLink'
import {useNavigate} from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      {/* <P>
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {DefaultLinks.map((link: FooterLink, index: number) => {
            return (
              <a
                href={link.to}
                key={`footer-link-static-${index}`}
                className='text-muted text-hover-primary px-2'
                target='_blank'
              >
                {link.label}
              </a>
            )
          })}
        </div>
      </div>
      <div className='d-flex flex-center flex-column-auto p-5'>
        <div className='d-flex align-items-center fs-6'>
          <p>{CopyrightFootNote}</p>
        </div>
      </div>
    </P> */}
      <div className='text-dark order-2 order-md-1'>{CopyrightFootNote}</div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        {DefaultLinks.map((link: FooterLink, index: number) => {
          return (
            <li className='menu-item'>
              <a className='menu-link px-2' href='#' onClick={() => navigate(`${link.to}`)}>
                {link.label}
              </a>
            </li>
          )
        })}
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
