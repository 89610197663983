
enum AgeGroupEnum {
  PreSchoolKindergarten = 'PreSchoolKindergarten',
  Elementary = 'Elementary',
  JuniorHigh = 'JuniorHigh',
  SeniorHigh = 'SeniorHigh',
  PreCollege = 'PreCollege',
  College = 'College',
  University = 'University',
  Adult = 'Adult',
  Senior = 'Senior',
}

function getAgeGroup(): string[] {
  return Object.values(AgeGroupEnum);
}

function printAgeGroup(value: AgeGroupEnum): string | undefined {
  switch (value) {
    case AgeGroupEnum.PreSchoolKindergarten:
      return 'Pre-School or Kindergarten';
    case AgeGroupEnum.Elementary:
      return 'Elementary (Grades 1-6)';
    case AgeGroupEnum.JuniorHigh:
      return 'Junior High (Grades 7-9)';
    case AgeGroupEnum.SeniorHigh:
      return 'Senior High (Grades 9-12)';
    case AgeGroupEnum.PreCollege:
      return 'Pre-College';
    case AgeGroupEnum.College:
      return 'College';
    case AgeGroupEnum.University:
      return 'University';
    case AgeGroupEnum.Adult:
      return 'Adult';
    case AgeGroupEnum.Senior:
      return 'Senior';
    default:
      return undefined;
  }
}

export { getAgeGroup, printAgeGroup, AgeGroupEnum };
