import {convertEnumToOptionsWithCleanLabel} from '../utils'

enum VenueExtrasEnum {
  GreenRoom = 'GreenRoom',
  MerchTable = 'MerchTable',
  MerchPerson = 'MerchPerson',
  DoorGreeterticketTaker = 'DoorGreeterticketTaker',
  PrivateBathroom = 'PrivateBathroom',
  Accommodations = 'Accommodations',
  Transportation = 'Transportation',
  FoodTabbarTab = 'FoodTabbarTab',
  ParkingSpaceloadingZone = 'ParkingSpaceloadingZone',
}

type VenueExtrasType = typeof VenueExtrasEnum[keyof typeof VenueExtrasEnum]

function getVenueExtrasTypes(): VenueExtrasType[] {
  return Object.values(VenueExtrasEnum)
}

function printVenueExtras(value: VenueExtrasEnum): string | undefined {
  switch (value) {
    case VenueExtrasEnum.GreenRoom:
      return 'Green Room'
    case VenueExtrasEnum.MerchTable:
      return 'Merch Table'
    case VenueExtrasEnum.MerchPerson:
      return 'Merch Person'
    case VenueExtrasEnum.DoorGreeterticketTaker:
      return 'Door Greeter / Ticket Taker'
    case VenueExtrasEnum.PrivateBathroom:
      return 'Private Bathroom'
    case VenueExtrasEnum.Accommodations:
      return 'Accommodations'
    case VenueExtrasEnum.Transportation:
      return 'Transportation'
    case VenueExtrasEnum.FoodTabbarTab:
      return 'Food Tab / Bar Tab'
    case VenueExtrasEnum.ParkingSpaceloadingZone:
      return 'Parking Space / Loading Zone'
    default:
      return ''
  }
}

function getVenueExtrasEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(VenueExtrasEnum, printVenueExtras)
}

export {getVenueExtrasTypes, printVenueExtras, VenueExtrasEnum, getVenueExtrasEnumOptions}
