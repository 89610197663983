import {ReactNode} from 'react'
import {DropDownItem} from '../../interfaces/DropDownItem'

interface Props {
  id: string
  owner: string
  label: string
  items: DropDownItem[]
  btnClass?: string

}

const DropdownButtonMenu = ({
  id,
  label,
  owner,
  items,
  btnClass = 'btn-secondary'
}: Props) => {
  return (
    <>
      <div className='dropdown'>
        <button
          className={`btn  ${btnClass} dropdown-toggle`}
          type='button'
          id={id}
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          {label}
        </button>
        <ul className='dropdown-menu' aria-labelledby={id}>
          {items.map((item: DropDownItem, index) => {
            return (
              <li key={`drop-down-item-${index}`}>
                {item.otherLink ? item.otherLink : (
                  (
                    <button
                      className='dropdown-item'
                      type='button'
                      onClick={() => item.onClick(owner)}
                    >
                      {item.label}
                    </button>
                  )
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default DropdownButtonMenu
