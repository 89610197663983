import clsx from 'clsx'
import {HeaderUserMenu} from './HeaderUserMenu'
import {useLayout, toAbsoluteUrl, ThemeModeSwitcher} from '../../../infrastructure/deps/metronic/v2'
import {AiOutlineBell, AiOutlineUpload} from 'react-icons/ai'
import {useAuth} from '../../../infrastructure/local/auth'
import {capitalizeFirstLetter} from '../../../domain/utils'
import {CgMenuGridO} from 'react-icons/cg'

const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const {currentUser} = useAuth()

  const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <AiOutlineBell size={24} />
        </div>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='fs-2'>
            <CgMenuGridO size={36} />
          </div>
        </div>
        <HeaderUserMenu />
      </div>
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            {/* <KTIcon iconName='text-align-left' className={btnIconClass} /> */}
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
