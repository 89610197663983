/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {ForgotPassword} from './forgotpassword/ForgotPassword'
import {ChangePassword} from './changepassword/ChangePassword'
import {VerifyEmail} from './verifyemail/VerifyEmail'
import {Login} from './login/Login'
import {PageTitle, toAbsoluteUrl} from '../../../infrastructure/deps/metronic/v2'
import {PageTitleSuffix} from '../../constants/PageConstants'
import {CopyrightFootNote, DefaultLinks} from '../../constants/FooterConstants'
import {FooterLink} from '../../interfaces/FooterLink'

const AuthLayout = () => {
  const navigate = useNavigate()
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={
        {
          // backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
        }
      }
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <a href='#' className='mb-5'>
          <img alt='Logo' src={toAbsoluteUrl('/images/usca-wlogo-md.png')} className='h-180px' />
        </a>
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>

      {/* FOOTER */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-bold fs-6'>
          {DefaultLinks.map((link: FooterLink, index: number) => {
            return (
              <a
                href='#'
                onClick={() => navigate(`${link.to}`)}
                className='text-muted text-hover-primary px-2'
              >
                {link.label}
              </a>
            )
          })}
        </div>
      </div>

      <div className='d-flex flex-center flex-column-auto p-5'>
        <div className='d-flex align-items-center fs-6'>
          <p>
            <p>{CopyrightFootNote}</p>
          </p>
        </div>
      </div>
      {/* END FOOTER */}
      <div></div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route
        path='login'
        element={
          <>
            <Login />
          </>
        }
      />
      <Route
        path='forgot-password'
        element={
          <>
            <ForgotPassword />
          </>
        }
      />

      <Route
        path='change-password'
        element={
          <>
            <ChangePassword />
          </>
        }
      />

      <Route
        path='verify-email'
        element={
          <>
            <VerifyEmail />
          </>
        }
      />
      <Route
        index
        element={
          <>
            <Login />
          </>
        }
      />
    </Route>
  </Routes>
)

export {AuthPage}
