import { convertEnumToOptionsWithCleanLabel } from "../utils";

enum AgentTypeEnum {
  EventManagerEventTechnician = 'EventManagerEventTechnician',
  PromotorManagamentAgent = 'PromotorManagamentAgent',

  BookingAgentTalentBuyerContractor  = 'BookingAgentTalentBuyerContractor',
}

function getAgentTypes(): string[] {
  return Object.values(AgentTypeEnum);
}

function printAgent(value: AgentTypeEnum): string | undefined {
  switch (value) {
    case AgentTypeEnum.PromotorManagamentAgent:
      return 'Promotors / Managament Agents';
    case AgentTypeEnum.EventManagerEventTechnician:
      return 'Event Manager / Event Technician';
    case AgentTypeEnum.BookingAgentTalentBuyerContractor:
      return 'Booking Agent/ Talent Buyer / Contractor';
    default:
      return "";
  }
}

function getAgentTypeEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(AgentTypeEnum, printAgent)
}

export { getAgentTypes, printAgent, AgentTypeEnum,getAgentTypeEnumOptions };
