import {FC} from 'react'
import {RecordingStudioRehearsalSpaceFormProps} from '../../../../management/venue/props'
import {
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import {H5, InputTextArea, Title} from '../../../../../components'
import CheckBoxGroup from '../../../../../components/CheckBoxGroup/CheckBoxGroup'
import {
  RecordingStudioRehearsalSpaceEnum,
  printRecordingStudioRehearsalSpace,
} from '../../../../../../domain/enums/RecordingStudioRehearsalSpaceTypeEnum'
import {
  StudioSuppliesEnum,
  printStudioSupplies,
} from '../../../../../../domain/enums/StudioSuppliesEnum'
import {
  RecordingStudioExtrasEnum,
  printRecordingStudioExtras,
} from '../../../../../../domain/enums/RecordingStudioExtrasEnum'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import {
  RecordingStudioRentalTimesEnum,
  printRecordingStudioRentalTimes,
} from '../../../../../../domain/enums/RecordingStudioRentalTimesEnum'
import AddressCard from '../../../partials/AddressCard'
import VenueAdditionalInfoCard from '../../../partials/VenueAdditionalInfoCard'

const RecordingStudioRehearsalSpaceForm: FC<RecordingStudioRehearsalSpaceFormProps> = ({
  control,
  errors,
  watch,
  provinces,
}) => {
  const recordingStudioRehearsalSpaceTypeOptions = convertEnumToOptionsWithCleanLabel(
    RecordingStudioRehearsalSpaceEnum,
    printRecordingStudioRehearsalSpace
  )

  const recordingStudioRehearsalSuppliesOptions = convertEnumToOptionsWithCleanLabel(
    StudioSuppliesEnum,
    printStudioSupplies
  )

  const recordingStudioRehearsalExtrasOptions = convertEnumToOptionsWithCleanLabel(
    RecordingStudioExtrasEnum,
    printRecordingStudioExtras
  )

  const recordingStudioRentalTimesOptions = convertEnumToOptionsWithCleanLabel(
    RecordingStudioRentalTimesEnum,
    printRecordingStudioRentalTimes
  )
  return (
    <>
      <div className='col-md-6'>
        <CheckBoxGroup
          name={'subType'}
          label={'Type'}
          // errors={errors.musicVenueType?.message}
          options={recordingStudioRehearsalSpaceTypeOptions}
          // className={"'"}
          control={control}
          className={'mb-5'}
        />
      </div>

      <AddressCard
        id={'rsrs-vn-address'}
        label={'Venue Address'}
        control={control}
        errors={errors}
        watch={watch}
        provinceList={provinces}
        provinceLabel={'Province'}
        province={true}
        addressLineOne={true}
        addressLineTwo={true}
        city={true}
        postalCode={true}
        addressPhone={true}
        addressLineOneLabel={'Address Line 1 (required)'}
        addressLineTwoLabel={'Address Line 2'}
        cityLabel={'City (required)'}
        postalCodeLabel={'Postal Code'}
        addressPhoneLabel={'Venue Phone'}
      />

      <div className='col-md-6'>
        <CheckBoxGroup
          name={'suppliedEquipment'}
          label={'Do you supply the following?'}
          errors={errors.recordingStudioRehearsalSpaceSuppliedEquipment?.message}
          options={recordingStudioRehearsalSuppliesOptions}
          control={control}
          className={'mb-5'}
        />
      </div>

      <div className='col-md-12 mt-6'>
        <H5>The studio's backline and gear</H5>
        <InputTextArea
          control={control}
          rows={4}
          name={'backlineGear'}
          required={false}
          disabled={false}
          label={'What music equipment is supplied?'}
          errors={errors.name?.message}
          className='form-control mt-5'
          placeholder='Drums, amps, pedals, etc'
        />
      </div>

      <div className='col-md-6'>
        <CheckBoxGroup
          name={'extras'}
          label={'Extras'}
          options={recordingStudioRehearsalExtrasOptions}
          control={control}
          className={'mb-5'}
        />
      </div>

      <VenueAdditionalInfoCard control={control} errors={errors} />

      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Rental Information</Title>
      </div>
      <div className='col-md-6'>
        <CheckBoxGroup
          name={'recordingStudioRentalTimes'}
          label={'Rental times.'}
          options={recordingStudioRentalTimesOptions}
          control={control}
          className={'mb-5'}
        />
      </div>
      <PayRateInfoCard control={control} errors={errors} rateQuestion='Minimum rate HOUR.' />
    </>
  )
}

export default RecordingStudioRehearsalSpaceForm
