/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {lazy, useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle, toAbsoluteUrl} from '../../../infrastructure/deps/metronic/v2'
import {PageTitleSuffix} from '../../constants/PageConstants'
import {StaticLayout} from './StaticLayout'

const Glossary = lazy(() => import('./glossary/Glossary'))
const Help = lazy(() => import('./help/Help'))
const PrivacyPolicy = lazy(() => import('./privacypolicy/PrivacyPolicy'))
const TermsConditions = lazy(() => import('./termsandcondition/TermsConditions'))

const StaticPage = () => (
  <Routes>
    <Route element={<StaticLayout />}>
      <Route
        path='glossary'
        element={
          <>
            <Glossary />
          </>
        }
      />
      <Route
        path='terms-and-conditions'
        element={
          <>
            <TermsConditions />
          </>
        }
      />

      <Route
        path='privacy-policy'
        element={
          <>
            <PrivacyPolicy />
          </>
        }
      />

      <Route
        path='help'
        element={
          <>
            <Help />
          </>
        }
      />
      {/* Additional Routes for static pages below */}
    </Route>
  </Routes>
)

export {StaticPage}
