import { convertEnumToOptionsWithCleanLabel } from "../utils"

const BandSizeEnum = {
  SoloDuo: 'SoloDuo',
  Medium: 'Medium',
  Large: 'Large',
  XtraLarge: 'XtraLarge'
}

type BandSizeEnumType = typeof BandSizeEnum[keyof typeof BandSizeEnum]

function getBandSize(value: BandSizeEnumType): BandSizeEnumType[] {
  const output = []
  output.push(BandSizeEnum.SoloDuo)
  output.push(BandSizeEnum.Medium)
  output.push(BandSizeEnum.Large)
  output.push(BandSizeEnum.XtraLarge)
  return output
}
const bandSizeTypes = Object.keys(BandSizeEnum)

function printBandSize(value: BandSizeEnumType): string {
  switch (value) {
    case BandSizeEnum.SoloDuo:
      return 'Solo or Duo'
    case BandSizeEnum.Medium:
      return '3-5'
    case BandSizeEnum.Large:
      return '6-9'
    case BandSizeEnum.XtraLarge:
      return '9+'
    default:
      break
  }
  return "Unspecified"
}

function getBandSizeEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(BandSizeEnum, printBandSize)
}

export {BandSizeEnum, getBandSize, printBandSize, bandSizeTypes,getBandSizeEnumOptions}
