/* eslint-disable jsx-a11y/anchor-is-valid */
import {useForm} from '../../../infrastructure/deps/react-form'
import {
  AutoComplete,
  InputText,
  AutoCompleteMultiple,
  RadioGroupCard,
  Modal,
} from '../../components'

export function Design() {
  interface Option {
    value: string
    label: string
  }

  const someOptions: Option[] = [
    {value: 'option1', label: 'Option 1'},
    {value: 'option2', label: 'Option 2'},
    {value: 'option3', label: 'Option 3'},
  ]
  interface Fruit {
    value: string
    label: string
  }

  const fruits: Fruit[] = [
    {value: '1', label: 'Apple'},
    {value: '2', label: 'Banana'},
    {value: '3', label: 'Orange'},
  ]
  return (
    <div className='d-flex flex-column flex-column-fluid'>
    </div>
  )
}
