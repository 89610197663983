import {Option} from '../../ui/interfaces/Option'
import { convertEnumToOptionsWithCleanLabel } from '../utils/CommonUtil'

const RecordingStudioRehearsalSpaceEnum = {
  RehearsalSpaceWithBackline: 'RehearsalSpace',
  // RehearsalSpaceWithoutBackline: 'RehearsalSpaceWithoutBackline',
  LargeRecordingStudioWithLiveRoom: 'LargeRecordingStudioWithLiveRoom',
  SmallRecordingStudioNoLiveRoom: 'SmallRecordingStudioNoLiveRoom',
}

type RecordingStudioRehearsalSpaceEnumType =
  typeof RecordingStudioRehearsalSpaceEnum[keyof typeof RecordingStudioRehearsalSpaceEnum]

function getAllRecordingStudioRehearsalSpaces(
  value: RecordingStudioRehearsalSpaceEnumType
): RecordingStudioRehearsalSpaceEnumType[] {
  const spaces = []
  spaces.push(RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithBackline)
  // spaces.push(RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithoutBackline)
  spaces.push(RecordingStudioRehearsalSpaceEnum.LargeRecordingStudioWithLiveRoom)
  spaces.push(RecordingStudioRehearsalSpaceEnum.SmallRecordingStudioNoLiveRoom)
  return spaces
}

function getRecordingStudioRehearsalSpaceOptions(): Option[] {
  const spaceOptions: Option[] = []
  spaceOptions.push({
    value: RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithBackline,
    label: `${printRecordingStudioRehearsalSpace(
      RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithBackline
    )}`,
  })
  // spaceOptions.push({
  //   value: RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithoutBackline,
  //   label: `${printRecordingStudioRehearsalSpace(
  //     RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithoutBackline
  //   )}`,
  // })
  spaceOptions.push({
    value: RecordingStudioRehearsalSpaceEnum.LargeRecordingStudioWithLiveRoom,
    label: `${printRecordingStudioRehearsalSpace(
      RecordingStudioRehearsalSpaceEnum.LargeRecordingStudioWithLiveRoom
    )}`,
  })
  spaceOptions.push({
    value: RecordingStudioRehearsalSpaceEnum.SmallRecordingStudioNoLiveRoom,
    label: `${printRecordingStudioRehearsalSpace(
      RecordingStudioRehearsalSpaceEnum.SmallRecordingStudioNoLiveRoom
    )}`,
  })
  return spaceOptions
}


function getRecordingStudioRehearsalSpaceTypeOptions(){
  return convertEnumToOptionsWithCleanLabel(
    RecordingStudioRehearsalSpaceEnum,
    printRecordingStudioRehearsalSpace
  )
  
} 
function printRecordingStudioRehearsalSpace(value: RecordingStudioRehearsalSpaceEnumType): string {
  switch (value) {
    case RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithBackline:
      return 'Rehearsal Space'
    // case RecordingStudioRehearsalSpaceEnum.RehearsalSpaceWithoutBackline:
    //   return 'Rehearsal Space without Backline'
    case RecordingStudioRehearsalSpaceEnum.LargeRecordingStudioWithLiveRoom:
      return 'Large recording studio (with a live drum room)'
    case RecordingStudioRehearsalSpaceEnum.SmallRecordingStudioNoLiveRoom:
      return 'Small recording studio (no live drum room)'
    default:
      return ''
  }
}

const recordingStudioRehearsalSpaceTypes = Object.keys(RecordingStudioRehearsalSpaceEnum)

export {
  getAllRecordingStudioRehearsalSpaces,
  getRecordingStudioRehearsalSpaceOptions,
  printRecordingStudioRehearsalSpace,
  recordingStudioRehearsalSpaceTypes,
  getRecordingStudioRehearsalSpaceTypeOptions,
  RecordingStudioRehearsalSpaceEnum,
}
