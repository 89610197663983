import {FC} from 'react'
import {P, Title} from '../../../components'
import CheckBoxGroup from '../../../components/CheckBoxGroup/CheckBoxGroup'
import {convertEnumToOptionsWithCleanLabel} from '../../../../domain/utils'
import {OrganizationEnum, printOrganizationType} from '../../../../domain/enums/OrganizationEnum'
import {
  PerformancePreferenceEnum,
  printPerformancePreference,
} from '../../../../domain/enums/PerformancePreferenceEnum'
import {EthnicIdentityEnum, printEthnicIdentity} from '../../../../domain/enums/EthnicIdentityEnum '

export interface Props {
  control: any
  errors: any
}

const CommunityAdditionalInfoCard: FC<Props> = ({control, errors}) => {
  const ethnicIdentityOptions = convertEnumToOptionsWithCleanLabel(
    EthnicIdentityEnum,
    printEthnicIdentity
  )
  return (
    <>
      {/* <div className='col-md-6 mt-5'>
        <CheckBoxGroup
          label={
            'Are you a member of any of the following professional affiliations? (Click all that apply)'
          }
          name={'professionalAffiliations'}
          options={professionalAffiliations}
          // className={"'"}
          control={control}
          errors={errors.professionalAffiliations?.message}
          className={'mb-5'}
        />
      </div> */}

      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Additional Details</Title>
        <P>
          Sometimes it’s all about the details. These details will not be shared, unless it’s
          something the booker has requested.
        </P>

        <ul>
          <li>
            <P>ie. a venue in Quebec may be seeking a performer that speaks fluent French.</P>
          </li>
          <li>
            <P>
              ie. a Pride festival may primarily be searching for LGBTQ2S+ or female/non-binary
              performers.
            </P>
          </li>
        </ul>
      </div>
      {/* TODO:: add this field to api  */}
      <div className='col-md-12 mt-5'>
        <CheckBoxGroup
          label={'Select all that apply.'}
          name={'ethnicIdentity'}
          options={ethnicIdentityOptions}
          // className={"'"}
          control={control}
          errors={errors.ethnicIdentity?.message}
          className={'mb-5'}
        />
      </div>
    </>
  )
}

export default CommunityAdditionalInfoCard
