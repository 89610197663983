import {LocationFilter} from '../../../domain/entities/filters/LocationFilter'
import {ResponseData} from '../../../domain/models/responses/ResponseData'
import {isEmpty} from '../../../domain/utils'
import {LocationApiRoutes, UserApiRoutes} from '../../constants/api/v1'
import {Http} from '../../http'
import {Api} from './Api'

export class LocationApi implements Api {
  async useGetFilter(filter: LocationFilter): Promise<ResponseData> {
    // let url = LocationApiRoutes.FIlter
    // if (isEmpty(filter.type)) {
    //   url += `?type=${filter.type}&`
    // }
    // if (isEmpty(filter.cityId)) {
    //   url += `?cityId=${filter.cityId}`q
    // } else if (isEmpty(filter.provinceId)) {
    //   url += `?provinceId=${filter.provinceId}`
    // }
    const res = await Http.post(LocationApiRoutes.FIlter, filter)
    return res
  }
}
