import {FC} from 'react'
import {convertEnumToOptions} from '../../../../domain/utils'
import {YesNoOnlyEnum} from '../../../../domain/enums/YesNoEnum'
import {InputText} from '../../../components'
import ToggleButtonGroup from '../../../components/ToggleButtonGroup/ToggleButtonGroup'

export interface Props {
  rateQuestion: string
  rateSubQuestion?: string
  control: any
  errors: any
}

const PayRateInfoCard: FC<Props> = ({rateQuestion, control, errors}) => {
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)

  return (
    <>
      <div className='col-md-6'>
        <InputText
          control={control}
          errors={errors.artistHourlyRate?.message}
          type='number'
          required={false}
          disabled={false}
          name={'minimumPrice'}
          label={rateQuestion}
          className='form-control mt-5'
        />
      </div>
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'wouldYouLikeToReceiveCharity'}
          label={
            'Would you like to receive offers, to volunteer for charity/ non-profits? For every confirmed charity booking we will gift you a free connection with no "good-faith" fee required.'
          }
          type='exclusive'
          options={yesNoOnlyOptions}
          control={control}
        />
      </div>
    </>
  )
}

export default PayRateInfoCard
