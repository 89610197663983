import {BaseResponse} from '../../../domain/models'
import { BaseFilter } from '../../../domain/models/filters/BaseFilter'
import {OnboardingRequest} from '../../../domain/models/requests/common/OnboardingRequest'
import {SwitchUserRequest} from '../../../domain/models/requests/user/SwitchUserRequest'
import {UserRequest} from '../../../domain/models/requests/user/UserRequest'
import {ActionResponse} from '../../../domain/models/responses/ActionResponse'
import {ResponseData} from '../../../domain/models/responses/ResponseData'
import {UserApiRoutes} from '../../constants/api/v1'
import {Http} from '../../http'
import {Api} from './Api'

export class UserApi implements Api {
  async useCreate(request: Omit<UserRequest, 'id'>): Promise<BaseResponse> {
    const res = await Http.post<UserRequest>(UserApiRoutes.Create, request)
    return res
  }

  async useUserSwitchAccount(request: Omit<SwitchUserRequest, 'id'>): Promise<BaseResponse> {
    const res = await Http.post<UserRequest>(UserApiRoutes.Switch, request)
    return res
  }

  async useUserOnboarding(request: Omit<OnboardingRequest, 'id'>): Promise<BaseResponse> {
    const res = await Http.post<OnboardingRequest>(UserApiRoutes.NewOnboarding, request)
    return res
  }
  async useUpdate(request: UserRequest): Promise<ActionResponse> {
    const res = await Http.post<UserRequest>(UserApiRoutes.Update, request)
    return res
  }
  async useUserAccountReset(request: UserRequest): Promise<ActionResponse> {
    const res = await Http.post<UserRequest>(UserApiRoutes.ResetAccount, request)
    return res
  }
  async useDelete(request: UserRequest): Promise<ActionResponse> {
    const res = await Http.post<UserRequest>(UserApiRoutes.Delete, request)
    return res
  }
  async useGetFilter(filter: BaseFilter): Promise<ResponseData> {
    const url = `${UserApiRoutes.GetFiltered}?page=${filter.page}&pagesize=${filter.pageSize}`
    const res = await Http.get(url)
    return res
  }

  async useUserLogout(): Promise<ResponseData> {
    const url = UserApiRoutes.Logout
    const res = await Http.get(url)
    return res
  }
}
