import { Option } from "../../ui/interfaces/Option";
import { YesNoOnlyEnum, printYesNo } from "../enums/YesNoEnum";

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function determinePaymentForOther(value?: string): boolean {
  if (isNullOrEmpty(value)) return false;
  if (value?.toUpperCase() === YesNoOnlyEnum.Yes.toUpperCase()) return true;
  if (value?.toUpperCase() === YesNoOnlyEnum.No.toUpperCase()) return false;
  return false;
}

export function isEmpty(value: any): boolean {
  return value == null || value === "" || value === undefined;
}

export function isEmptyObject(obj: any): boolean {
  if (obj === undefined || obj === null) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function safeNullableString(value?: string | undefined | null): string {
  if (value === undefined || value === null) return "";
  if (isEmpty(value)) return "";
  return value;
}

export function safeNullableDateString(value?: Date): string {
  if (value === undefined) return "";
  if (isEmpty(value)) return "";
  return value.toString();
}

export function safeNullableInt(value?: number): number {
  if (value === undefined) return 0;
  if (isEmpty(value)) return 0;
  return value;
}

export function addToCollection(array: string[], item: string) {
  if (!Array.isArray(array)) {
    return [];
  }
  const newArray = [...array];
  if (!newArray.includes(item)) {
    newArray.push(item);
  }
  return newArray;
}

export function removeFromCollection(array: string[], item: string) {
  const index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  } else {
  }
  return array;
}

export function mapToOptionsList(array: any[] | undefined): Option[] {
  const optionsList: Option[] = [];
  if (isEmptyObject(array)) return optionsList;
  if (array === undefined) return optionsList;
  array.map((option) => {
    optionsList.push({
      label: option?.name,
      value: safeNullableString(option?.id),
    });
  });
  return optionsList;
}

export function filterByList(array: any[], id: number): any {
  if (array === undefined) return {};
  console.log("filterByList", array);
  console.log("filterByList", id);
  const selectedItem = array.filter((item) => item.id === id);
  return selectedItem[0];
}

export function displayErrorsFromSchema(
  validateRoadTravelSchema: any,
  setError: any
) {
  if (!isEmpty(validateRoadTravelSchema.errors)) {
    var listOfErrors = validateRoadTravelSchema.errors.details;
    listOfErrors.map((error: any) => {
      setError(`${error.context.key}`, error);
    });
  }
}

export function displayErrors(errors: any, setError: any) {
  if (!isEmptyObject(errors)) {
    var listOfErrors = errors.details;
    listOfErrors.map((error: any) => {
      setError(`${error.context.key}`, error);
    });
  }
}

export function convertEnumToOptions(enumObj: any): Option[] {
  const options: Option[] = [];
  if (isEmptyObject(enumObj)) return options;
  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      options.push({ value: key, label: enumObj[key] });
    }
  }
  return options;
}

export function convertEnumToOptionsWithCleanLabel(
  enumObj: any,
  printName: any
): Option[] {
  const options: Option[] = [];
  if (isEmptyObject(enumObj)) return options;
  for (const key in enumObj) {
    if (enumObj.hasOwnProperty(key)) {
      options.push({ value: key, label: printName(enumObj[key]) });
    }
  }
  return options;
}

export function getFileNameWithoutExtension(fileName: string) {
  const lastDotIndex = fileName.lastIndexOf(".");
  const cleanedName =
    lastDotIndex !== -1 ? fileName.substring(0, lastDotIndex) : fileName;
  return cleanedName.replace(/\s+$/, "");
}

export function buildMBATitleFromTags(inputString: string) {
  // Check if the input string is empty or null
  if (!inputString || inputString.trim() === "") {
    return "";
  } else {
    // return inputString.toLowerCase().replace(/\s/g, ',')
    return inputString.toLowerCase();
  }
}

export function addPrefixAndSuffix(
  inputString: string,
  prefix: string,
  suffix: string
) {
  let resultString = inputString;

  if (!isEmpty(prefix)) {
    resultString = `${prefix} ${resultString}`;
  }

  if (!isEmpty(suffix)) {
    resultString = `${resultString} ${suffix}`;
  }

  return resultString;
}

export function arrayToCommaSeparatedString(
  inputArray: Option[] | undefined
): string {
  if (inputArray === undefined) {
    return "";
  }
  const values: string[] = inputArray.map((option) => option.value);
  return values.join(",");
}

export function findAndReplace(
  inputString: string,
  replacements: Record<string, string>
): string {
  let resultString = inputString;

  Object.entries(replacements).forEach(([search, replace]) => {
    if (!isEmpty(resultString)) {
      const regex = new RegExp(search, "g");
      resultString = resultString.replace(regex, replace);
    }
  });

  return resultString;
}

export function getLength(value: string | null | undefined): number {
  console.log(value);
  if (value !== null && value !== undefined) {
    return value.length;
  } else {
    return 0;
  }
}

export function capitalizeText(input: string): string {
  const lowerCaseAll = input.toLowerCase();
  const changedCase = lowerCaseAll
    .replace(/\./g, "") // Remove full stops
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  return changedCase;
}

export function capitalizeFirstLetter(input: string) {
  if (input && typeof input === "string" && input.length > 0) {
    return input.charAt(0).toUpperCase();
  } else {
    return "";
  }
}

export function removeProtocolPrefix(url: string) {
  return url.replace(/^(https?|ftp):\/\//, "");
}

function isArrayEmpty(arr: any) {
  return Array.isArray(arr) && arr.length === 0;
}

export function isNullOrEmpty(value: any) {
  return (
    value === null || value === undefined || value === "" || isEmpty(value)
  );
}
export function isObject(value: any) {
  return typeof value === "object";
}
export function setDocumentTitle(title: string) {
  if (isNullOrEmpty(title)) return null;
  document.title = title;
}

export function isInteger(value: any) {
  return /^-?\d+$/.test(value);
}

export function isPositiveDecimal(value: any) {
  return !/\D/.test(value) || /^\d+\.\d+$/.test(value);
}

export function isAlphanumeric(value: any) {
  return !/\W/.test(value);
}

export function isAlphanumericSpaceAmpersandHyphen(value: any) {
  return !/^[\w &\-]+$/.test(value);
}

export function convertToUri(text: string) {
  const a = "àáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;";
  const b = "aaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------";
  const p = new RegExp(a.split("").join("|"), "g");
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function printJson(json: any) {
  console.log(JSON.stringify(json, null, 3));
}

export function kmbFormat(num: any) {
  if (isNullOrEmpty(num)) return 0;
  if (num > 9999 && num < 1000000) {
    return (num / 1000).toFixed(0) + "K";
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else if (num < 9999) {
    return numberWithCommas(num);
  }
}

export function numberWithCommas(num: any) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toKeyValueCollection(collection: any) {
  let newCollection: any[] = [];
  if (!isNullOrEmpty(collection.data)) {
    collection.data.forEach((item: any) => {
      newCollection.push({ key: item.name, value: item.id });
    });
  }
  return newCollection;
}

export function toKeyObjectValueCollection(collection: any) {
  let newCollection: any[] = [];
  if (!isNullOrEmpty(collection.data)) {
    collection.data.forEach((item: any) => {
      newCollection.push({ key: item.name, value: item });
    });
  }
  return newCollection;
}

export function findNameByIdInCollection(collection: any, id: string) {
  if (!isNullOrEmpty(collection.data) && collection.data.length > 0) {
    const searchResult = collection.data.find((obj: any) => obj.id === id);
    if (!isEmptyObject(searchResult)) return searchResult.name;
  }
  return "";
}

export function updateCollection(
  key: string,
  collection: any,
  setCollection: any
) {
  let selectedItems = collection.selected;
  let isKeySelected = selectedItems.indexOf(key);
  if (isKeySelected > -1) {
    selectedItems.splice(isKeySelected, 1);
  } else {
    selectedItems.push(key);
  }
  setCollection({
    selected: selectedItems,
  });
}

export function safeValue(value: any) {
  if (isNullOrEmpty(value)) {
    return "";
  }
  return value;
}

export function safeLabelValue(value: any, emptyLabel = "Unspecified") {
  console.log("value", value);
  if (isNullOrEmpty(value)) {
    return emptyLabel;
  }
  return value;
}

export function isAuthenticated(value: any) {
  if (isNullOrEmpty(value) || isEmptyObject(value)) {
    return false;
  }
  if (!isNullOrEmpty(value) && !isEmptyObject(value)) {
    if (
      !isNullOrEmpty(value.user) &&
      !isEmptyObject(value.token) &&
      !isEmptyObject(value.user)
    ) {
      return true;
    }
  }
  return false;
}

export function isOnboarding(value: any) {
  if (isNullOrEmpty(value) || isEmptyObject(value)) {
    return false;
  }
  if (!isNullOrEmpty(value) && !isEmptyObject(value)) {
    const me = value.user;
    if (!isNullOrEmpty(me)) {
      if (!isNullOrEmpty(me.isOnboarding)) {
        if (me.isOnboarding) return true;
      }
    }
  }
  return false;
}

export function isLoggedIn(value: any) {
  if (isNullOrEmpty(value)) {
    return false;
  }
  if (isNullOrEmpty(value) && value.loading === false) {
    return false;
  }

  if (!isNullOrEmpty(value)) {
    if (!isNullOrEmpty(value.userDetails)) {
      return true;
    }
    if (!isNullOrEmpty(value.username)) {
      return true;
    }
  }
  return false;
}

export function safeBoolean(value: any) {
  if (isNullOrEmpty(value)) return undefined;
  if (value === true) return true;
  if (value === false) return false;
  value = value.toLowerCase();
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }
  return undefined;
}

export function truncateString(string: string, maxLength: number) {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  } else {
    return string;
  }
}

export function isUsername(value: string) {
  const validation = /^[a-zA-Z0-9_@\.]+$/.exec(value);
  const isAcceptable = !!validation;
  return isAcceptable;
}

// export function currentUserMenu(sessionRoles) {
//   if (isNullOrEmpty(sessionRoles) || isObjectEmpty(sessionRoles)) return menu.onboarding
//   const nonAdminUsers = [roles.Agent, roles.Artist, roles.Venue]
//   const adminUsers = [roles.PlatformEditor]
//   if (sessionRoles.find((role) => nonAdminUsers?.includes(role))) return menu.standard
//   if (sessionRoles.find((role) => adminUsers?.includes(role))) return menu.privileged
//   return menu.onboarding
// }

export function safeValueBoolean(value: any) {
  if (isNullOrEmpty(value)) return null;
  if (value === true) return true;
  if (value === false) return false;
  value = value.toLowerCase();
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  }
  return null;
}

export function safeValueFloat(value: any) {
  if (isNullOrEmpty(value)) return null;
  const parsedFloat = parseFloat(value);
  if (isNaN(parsedFloat)) {
    return null;
  }
  return parsedFloat;
}

export function safeYesNoBoolean(value: any): boolean | null {
  if (isNullOrEmpty(value)) return null;
  value = value.toLowerCase();
  if (value === "no") {
    return false;
  } else if (value === "yes") {
    return true;
  }
  return null;
}

// function toCommaSeparatedString(arr: ) {
//   // Check if the input is null or undefined
//   if (arr == null) {
//       return "";
//   }

//   // Check if the input is not an array
//   if (!Array.isArray(arr)) {
//       return "";
//   }

//   // Use join to convert the array to a comma-separated string
//   return arr.join(", ");
// }

export function safeNaEnum(value: any) {
  if (isNullOrEmpty(value)) return "NotApplicable";
  return value;
}

export function safeNullableEnum(value: any) {
  if (isNullOrEmpty(value)) return null;
  return value;
}

export function safeNullableNumber(value: any) {
  if (isNullOrEmpty(value)) return null;
  return value;
}

export function safeYesNoBothCollectionBoolean(property: any, value: string) {
  if (isNullOrEmpty(value) || isNullOrEmpty(property)) return null;
  value = value.toLowerCase();
  property = property.toLowerCase();
  if (value === "both") return true;
  if (value === property) return true;
  return null;
}

export function safeItemSelectedInGenre(property: any, value: any) {
  if (isNullOrEmpty(value)) return null;
  if (isEmptyObject(value)) return null;
  if (value.length === 0) return null;
  var isSelected = value.find((item: any) => item.alias === property);
  if (isNullOrEmpty(isSelected)) return null;
  return true;
}

export function safeItemSelectionInCollection(property: any, value: any) {
  console.log("safeItemSelectionInCollection", value);
  if (isNullOrEmpty(value) || isEmptyObject(value) || isNullOrEmpty(property))
    return null;
  if (value.length === 0) return null;
  var isSelected = value.find(
    (item: any) => item.toLowerCase() === property.toLowerCase()
  );
  if (isNullOrEmpty(isSelected)) return null;
  return true;
}

export function safeItemSelectionInCollectionBoolean(
  property: any,
  value: any
): Boolean {
  console.log("safeItemSelectionInCollection", value);
  if (isNullOrEmpty(value) || isEmptyObject(value) || isNullOrEmpty(property))
    return false;
  if (value.length === 0) return false;
  var isSelected = value.find(
    (item: any) => item.toLowerCase() === property.toLowerCase()
  );
  if (isNullOrEmpty(isSelected)) return false;
  return true;
}

export function convertSnakeCaseToSentence(snakeCaseString: string) {
  const words = snakeCaseString.split("_");
  const sentence = words
    .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
    .join(" ");
  return sentence;
}

export function convertSequelizeToJoi(dataTypesObject: any) {
  const items = [];
  for (const key in dataTypesObject) {
    switch (dataTypesObject[key]) {
      case "INTEGER":
        items.push(
          `${key}: Joi.number().optional().allow(null).label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      case "STRING":
        items.push(
          `${key}: Joi.string().optional().allow(null).label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      case "BOOLEAN":
        items.push(
          `${key}: Joi.boolean().optional().allow(null).label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      case "FLOAT":
        items.push(
          `${key}: Joi.number().precision(2).optional().label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      case "ENUM":
        items.push(
          `${key}: Joi.string().optional().valid('NO', 'YES').label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      case "DATE":
        items.push(
          `${key}: Joi.date().optional().allow(null).label("${convertSnakeCaseToSentence(
            key
          )}")`
        );
        break;
      default:
        break;
    }
  }
  return `{${items.join(", \n")}}`;
}

export function safeYesNoFromBoolean(value: any) {
  if (isNullOrEmpty(value)) return null;
  if (value === false) {
    return "No";
  } else if (value === true) {
    return "Yes";
  }
  return null;
}

export function safeYesNoFromEnum(value: any) {
  if (isNullOrEmpty(value)) return null;
  if (value === "NO") {
    return "No";
  } else if (value === "YES") {
    return "Yes";
  }
  return null;
}

export function safeLocationId(
  countryId = 1,
  provinceId = null,
  cityId = null
): number | null {
  // no location picked
  if (
    isNullOrEmpty(countryId) &&
    isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return null;
  //country wide selection
  if (
    !isNullOrEmpty(countryId) &&
    isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return countryId;

  //province/state wide selection
  if (
    !isNullOrEmpty(countryId) &&
    !isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return provinceId;

  //citu specific selection
  if (
    !isNullOrEmpty(countryId) &&
    !isNullOrEmpty(provinceId) &&
    !isNullOrEmpty(cityId)
  )
    return cityId;

  return null;
}

export function safeLocationTextId(
  countryId = 1,
  provinceId: Option[],
  cityId: Option[]
): string | null {
  // no location picked
  if (
    isNullOrEmpty(countryId) &&
    isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return "";
  //country wide selection
  if (
    !isNullOrEmpty(countryId) &&
    isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return countryId.toString();
  //province/state wide selection
  if (
    !isNullOrEmpty(countryId) &&
    !isNullOrEmpty(provinceId) &&
    isNullOrEmpty(cityId)
  )
    return mapOptionsToCommaString(provinceId);

  //city specific selection
  if (
    !isNullOrEmpty(countryId) &&
    !isNullOrEmpty(provinceId) &&
    !isNullOrEmpty(cityId)
  ) {
    const provinces = mapOptionsToCommaString(provinceId);
    const cities = mapOptionsToCommaString(cityId);
    return `${provinces},${cities}`;
  }
  return null;
}

// export function mapEnumToOption(value, printName) {
//   let optionsList: any[] = []
//   if (value.length > 0) {
//     value.map((option, index) => {
//       optionsList.push({
//         label: printName(option),
//         alias: option,
//         id: index,
//       })
//     })
//     return optionsList
//   }
//   return []
// }

// export function mapToOptions(collection: any) {
//   let newCollection = []
//   if (!isNullOrEmpty(collection.data)) {
//     collection.data.forEach((item) => {
//       newCollection.push({label: item.name, alias: item.name, value: item.id})
//     })
//   }
//   return newCollection
// }

export function mapOptionsValueToString(object: any) {
  if (isNullOrEmpty(object)) return "";
  if (isArrayEmpty(object)) return "";
  const listAsString = object.map((item: any) => item.value).join(",");
  return listAsString;
}

export function mapOptionsIdToString(object: any) {
  if (isNullOrEmpty(object)) return "";
  if (isArrayEmpty(object)) return "";
  const listAsString = object.map((item: any) => item.id).join(",");
  return listAsString;
}

export function mapEnumsToString(array: any) {
  if (isNullOrEmpty(array)) return "";
  if (isArrayEmpty(array)) return "";
  return array.join(",");
}

export function mapOptionsToCommaString(options?: Option[]): string {
  if (options === undefined || options === null) return "";
  // Extract the 'value' from each Option and join them into a comma-separated string
  return options.map((option) => option.value).join(",");
}

export function mapCommaStringToOptions(
  options?: string | null,
  printName?: any
): Option[] {
  let newOptions: Option[] = [];
  if (options === undefined || options === null) return newOptions;
  // Extract the 'value' from each Option and join them into a comma-separated string
  const itemsList = options.split(",");
  itemsList.map((item) => {
    newOptions.push({ value: item, label: printName(item) });
  });
  return newOptions;
}

export function mapBooleanToYesNo(booleanValue?: boolean | null): string {
  if (booleanValue === true) {
    return YesNoOnlyEnum.Yes;
  }
  if (booleanValue === false) {
    return YesNoOnlyEnum.No;
  }
  return "";
}

export function mapBooleanToYesNoOptions(
  booleanValue?: boolean | null
): Option[] {
  let newOptions: Option[] = [];
  if (booleanValue === undefined || booleanValue === null) return newOptions;
  if (booleanValue === true) {
    newOptions.push({
      value: YesNoOnlyEnum.Yes,
      label: printYesNo(YesNoOnlyEnum.Yes),
    });
    return newOptions;
  }
  if (booleanValue === false) {
    newOptions.push({
      value: YesNoOnlyEnum.No,
      label: printYesNo(YesNoOnlyEnum.No),
    });
    return newOptions;
  }
  return newOptions;
}

export function mapOptionsToNumberArray(options?: Option[]): number[] {
  if (options === undefined || options === null) return [];
  // Extract the 'value' from each Option, parse them as numbers, and return as an array
  return options.map((option) => Number(option.value));
}

export function intToNumberArray(value?: number): number[] {
  let numbersList: number[] = [];
  if (value === undefined || value === null) return numbersList;
  numbersList.push(value);
  return numbersList;
}

export function getRequiredValidation(rule: any) {
  if (rule && rule.type === "required" && rule.ref && rule.ref.name) {
    const fieldName = rule.ref.name;
    return `This field is required.`;
  }
  return "";
}

export function reorderArray(array: any[], searchValue: string): any[] {
  const index = array.findIndex((item) => item.id === searchValue);
  if (index !== -1) {
    const [item] = array.splice(index, 1);
    array.unshift(item);
  }
  return array;
}

export function extractErrorMessages(errorResponse: any): string[] | null {
  if (
    errorResponse == null ||
    errorResponse == undefined ||
    isEmptyObject(errorResponse)
  )
    return null;
  return errorResponse.errors.details;
}


export function isNotNullOrUndefined(obj: any): boolean {
  return obj !== null && typeof obj !== 'undefined'
}

export function isObjectEmpty(obj: any): boolean {
  if(obj === null || typeof obj === 'undefined') return true
  return isNotNullOrUndefined(obj) && Object.keys(obj).length === 0
}
