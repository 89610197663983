import {convertEnumToOptionsWithCleanLabel} from '../utils/CommonUtil'

enum StudioSuppliesEnum {
  AProducer = 'AProducer',
  ASoundEngineer = 'ASoundEngineer',
  Monitorsheadphones = 'Monitorsheadphones',
  MicrophonesAndMicStands = 'MicrophonesAndMicStands',
  VocalBooth = 'VocalBooth',
  ExtraneousPercussion = 'ExtraneousPercussion',
  TrackselectronicsabletonCapabilities = 'TrackselectronicsabletonCapabilities',
  DrumSet = 'DrumSet',
}

type StudioSuppliesType = typeof StudioSuppliesEnum[keyof typeof StudioSuppliesEnum]

function getStudioSuppliesTypes(): StudioSuppliesType[] {
  return Object.values(StudioSuppliesEnum)
}

function getStudioSuppliesEnumOptions() {
  return convertEnumToOptionsWithCleanLabel(StudioSuppliesEnum, printStudioSupplies)
}

function printStudioSupplies(value: StudioSuppliesEnum): string | undefined {
  switch (value) {
    case StudioSuppliesEnum.AProducer:
      return 'A Producer'
    case StudioSuppliesEnum.ASoundEngineer:
      return 'A Sound Engineer'
    case StudioSuppliesEnum.Monitorsheadphones:
      return 'Monitors/Headphones'
    case StudioSuppliesEnum.MicrophonesAndMicStands:
      return 'Microphones and Mic Stands'
    case StudioSuppliesEnum.VocalBooth:
      return 'Vocal Booth'
    case StudioSuppliesEnum.ExtraneousPercussion:
      return 'Extraneous Percussion'
    case StudioSuppliesEnum.TrackselectronicsabletonCapabilities:
      return 'Tracks/Electronics/Ableton capabilities'
    case StudioSuppliesEnum.DrumSet:
      return 'Drum Set'
    default:
      return ''
  }
}

export {getStudioSuppliesTypes, printStudioSupplies, StudioSuppliesEnum,getStudioSuppliesEnumOptions}
