
enum RecordingStudioRentalTimesEnum {
HourlyRentals = 'HourlyRentals',
DailyRentals = 'DailyRentals',
WeeklyRentals = 'WeeklyRentals',
LockoutsForExtendedPeriodsOfTime = 'LockoutsForExtendedPeriodsOfTime',
}

type RecordingStudioRentalTimesType = typeof RecordingStudioRentalTimesEnum[keyof typeof RecordingStudioRentalTimesEnum]

function getRecordingStudioRentalTimesTypes(): RecordingStudioRentalTimesType[] {
        return Object.values(RecordingStudioRentalTimesEnum);
      } 



function printRecordingStudioRentalTimes(value: RecordingStudioRentalTimesEnum): string | undefined { 
switch (value) { 
       case RecordingStudioRentalTimesEnum.HourlyRentals:
        return 'Hourly Rentals';
       case RecordingStudioRentalTimesEnum.DailyRentals:
        return 'Daily Rentals';
       case RecordingStudioRentalTimesEnum.WeeklyRentals:
        return 'Weekly Rentals';
       case RecordingStudioRentalTimesEnum.LockoutsForExtendedPeriodsOfTime:
        return 'Lockouts for extended periods of time';
      default:
    return "";
        }
     }


export { getRecordingStudioRentalTimesTypes, printRecordingStudioRentalTimes, RecordingStudioRentalTimesEnum }; 
