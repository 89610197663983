const EngineerTypeEnum = {
  LiveFOHEngineer: 'LiveFOHEngineer',
  MixProductionEngineer: 'MixProductionEngineer',
  MasteringEngineer: 'MasteringEngineer',
  RecordingEngineer: 'RecordingEngineer',
  MonitorEngineer: 'MonitorEngineer',
}

type EngineerTypeEnumType = typeof EngineerTypeEnum[keyof typeof EngineerTypeEnum]

function getEngineerType(value: EngineerTypeEnumType): EngineerTypeEnumType[] {
  const output = []
  output.push(EngineerTypeEnum.LiveFOHEngineer)
  output.push(EngineerTypeEnum.MixProductionEngineer)
  output.push(EngineerTypeEnum.MasteringEngineer)
  output.push(EngineerTypeEnum.RecordingEngineer)
  output.push(EngineerTypeEnum.MonitorEngineer)
  return output
}

function printEngineerType(value: EngineerTypeEnumType): string {
  switch (value) {
    case EngineerTypeEnum.LiveFOHEngineer:
      return 'Live & FOH Engineer'
    case EngineerTypeEnum.MixProductionEngineer:
      return 'Mix & Production Engineer'
    case EngineerTypeEnum.MasteringEngineer:
      return 'Mastering Engineer'
    case EngineerTypeEnum.RecordingEngineer:
      return 'Recording Engineer'
    case EngineerTypeEnum.MonitorEngineer:
      return 'Monitor Engineer'
    default:
      return ''
      break
  }
}

export {getEngineerType, printEngineerType, EngineerTypeEnum}
