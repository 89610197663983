import {Option} from '../../interfaces/Option'
import {addToCollection, isEmpty, removeFromCollection} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'
import P from '../P/P'

interface CheckBoxProps {
  errors?: any
  label: string
  name: string
  options: Option[]
  className: string
  control: Control<any, any>
}

const CheckBoxGroup = ({label, name, errors, className, control, options}: CheckBoxProps) => {
  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={[]} // Set defaultValue to an empty array for a checkbox group
      render={({field: {onChange, value}}) => (
        <>
          <div>
            <P>{label}</P>
          </div>
          <div className=''>
            {options.map((option) => (
              <>
                <div className='col-md-4'>
                  <div className={`form-check form-check-custom form-check-solid mb-3`}>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      style={{display: 'block'}}
                      onChange={(event) => {
                        //console.log('CheckBox', value)
                        if (event.target.checked) {
                          const list = addToCollection(value, event.target.value)
                          console.log("curr value", value)
                          console.log("addToCollection", list)
                          onChange(list)
                        } else {
                          const list = removeFromCollection(value, event.target.value)
                          console.log("removeFromCollection", list)
                          onChange(list)
                        }
                      }}
                      value={option.value}
                      
                    />
                    <label className='form-check-label' key={option.value}>
                      {option.label}
                    </label>
                  </div>
                </div>
              </>
            ))}
            {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}
          </div>
        </>
      )}
    />
  )
}

export default CheckBoxGroup
