import {ReactNode} from 'react'
import {AiOutlineClose} from 'react-icons/ai'
import Title from '../Title/Title'

interface Props {
  id: string
  title: string
  children: ReactNode
  buttons: ReactNode
  visible: boolean
  onClose: () => void
  // className?: string
}

const Modal = ({id, children, title, buttons, visible, onClose}: Props) => {
  if (visible) {
    return (
      <>
        {visible ? <div className={`modal-backdrop show`}></div> : ''}
        <div
          className={`modal fade ${visible ? 'show' : ''}`}
          tabIndex={-1}
          id={id}
          style={{display: 'block'}}
          aria-modal='true'
          role='dialog'
        >
          <div className='modal-dialog modal-xl'>
            <div className='modal-content'>
              <div className='modal-header'>
                <Title type='xxs'>{title}</Title>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  onClick={onClose}
                  aria-label='Close'
                >
                  <AiOutlineClose />
                </div>
              </div>
              <div className='modal-body'>{children}</div>
              <div className='modal-footer'>{buttons}</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return <></>
}

export default Modal
