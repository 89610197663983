
  import {useQueryClient, useMutation} from '../../../infrastructure/deps/react-query'
  import { UserRepositoryImpl } from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import { SwitchUserRequest } from '../../models/requests/user/SwitchUserRequest'
  import { UserRequest } from '../../models/requests/user/UserRequest'
  
  
  export const useUserSwitchAccount = (repository: UserRepositoryImpl ) => {
    const queryClient = useQueryClient()
    const switchAccount = useMutation({
      mutationFn: (request: Omit<SwitchUserRequest, 'id'>) => repository.useUserSwitchAccount(request),
      onSuccess: (res) => {
        console.log(res)
        queryClient.invalidateQueries({queryKey: ['createUser']})
        // notify.success('Created Correctly!')
      },
      onError: (error) => {
        console.log(error)
        // logger.error("Something goes wrong..");
      },
    })
    return switchAccount
  }
  
  
