import React, {useState, ReactNode} from 'react'
import StepperItem from './StepperItem'

interface Step {
  title: string
  description: string
  content: ReactNode
  selectable: boolean
}

interface StepperProps {
  steps: Step[]
  currentStep: number
  setCurrentStep: any
  currentDefinitiveStep: number
}

const Stepper: React.FC<StepperProps> = ({steps, currentStep, setCurrentStep, currentDefinitiveStep}) => {
  const stepperNavStyle =  steps.length>1?'stepper-nav flex-center flex-wrap mb-10':'stepper-nav  mb-10'
  return (
    <div className='stepper stepper-pills' id='kt_stepper_example_basic'>
      <div className={`${stepperNavStyle}`}>
        {steps.map((step, index) => (
          <StepperItem
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            index={index}
            title={step.title}
            description={step.description}
            content={step.content}
            selectable={step.selectable}
            currentDefinitiveStep={currentDefinitiveStep}
          />
        ))}
      </div>
      <div className='mb-5'>
        <div className='flex-column current' data-kt-stepper-element='content'>
          {steps.map((step, index) => {
            //debugger
            if(currentStep === (index+1)) return <> {step.content}</>
          })}
        </div>
      </div>
    </div>
  )
}

export default Stepper
