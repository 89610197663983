import {FC} from 'react'
import ToggleButtonGroup from '../../../components/ToggleButtonGroup/ToggleButtonGroup'
import { VenueEnum, printVenueType } from '../../../../domain/enums/VenueEnum'
import { YesNoEnum, YesNoOnlyEnum } from '../../../../domain/enums/YesNoEnum'
import { convertEnumToOptions, convertEnumToOptionsWithCleanLabel } from '../../../../domain/utils'
import { RenderWhenGrid } from '../../../components'
import { VenueFormProps } from '../../management/venue/props'
import FestivalPopUpForm from '../../shared/forms/onboarding/venue/FestivalPopUpForm'
import MusicVenueForm from '../../shared/forms/onboarding/venue/MusicVenueForm'
import RecordingStudioRehearsalSpaceForm from '../../shared/forms/onboarding/venue/RecordingStudioRehearsalSpaceForm'
import RetailRentalRepairShopForm from '../../shared/forms/onboarding/venue/RetailRentalRepairShopForm'
import SchoolAcademyLessonsForm from '../../shared/forms/onboarding/venue/SchoolAcademyLessonsForm'
import ContactCard from '../../shared/partials/ContactCard'


const VenueOnboardingForm: FC<VenueFormProps> = ({control, errors, watch, provinces}) => {
  const yesNoOptions = convertEnumToOptions(YesNoEnum)
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const venueType = watch('venueType')
  const artistTypeOptions = convertEnumToOptionsWithCleanLabel(VenueEnum, printVenueType)
  return (
    <>
      <div className='row'>
        {/* Update venue stuff here */}
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'venueType'}
            label={'What type of venue?'}
            type='exclusive'
            options={artistTypeOptions}
            control={control}
          />
        </div>
        {/* Festival Pop Up */}
        {/* <RenderWhenGrid visible={venueType === VenueEnum.Festivals}>
          <FestivalPopUpForm control={control} errors={errors} watch={watch} provinces={provinces}/>
        </RenderWhenGrid>  */}
        {/* Music Venue */}
        <RenderWhenGrid visible={venueType === VenueEnum.Music}>
          <MusicVenueForm control={control} errors={errors} watch={watch} provinces={provinces}/>
        </RenderWhenGrid>
        {/* Recording Studio / Rehearsal Space */}
        <RenderWhenGrid visible={venueType === VenueEnum.RecordingStudioRehearsal}>
          <RecordingStudioRehearsalSpaceForm control={control} errors={errors} watch={watch} provinces={provinces} />
        </RenderWhenGrid>
        {/* Retail Rental Repair Shop  */}
        <RenderWhenGrid visible={venueType === VenueEnum.RetailRentalRepairShop}>
          <RetailRentalRepairShopForm control={control} errors={errors} watch={watch} provinces={provinces}/>
        </RenderWhenGrid>
        {/* School Academy / Lessons  */}
        <RenderWhenGrid visible={venueType === VenueEnum.SchoolAcademyLesson}>
          <SchoolAcademyLessonsForm control={control} errors={errors} watch={watch} provinces={provinces}/>
        </RenderWhenGrid>

        <ContactCard
          id={''}
          label='Contact Information'
          control={control}
          errors={errors}
          website={false}
          //firstName={true}
          name={true}
          phone={true}
          emailAddress={true}
          // googlePhotos = false,
          // googleReviews = false,
          // wikipedia = false,
          spotify={true}
          // appleMusic = false,
          // googlePlay = false,
          facebook={false}
          instagram={false}
          youtube={false}
          tiktok={false}
          shareProfile={false}
          representingArtist={false}
          showSocials={false}
          representingLabelOrAgency={false}
          
        />



      </div>
    </>
  )
}

export default VenueOnboardingForm
